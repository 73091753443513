
import {throwError as observableThrowError,  Observable ,  EMPTY } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Injectable, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SecurityService } from './security.service';
import { Auth } from 'aws-amplify';


const getAuthHeader = (session) => `Bearer ${session.getAccessToken().getJwtToken()}`;

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor
{

constructor(private router: Router, private securityService: SecurityService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = localStorage.getItem('bearerToken');
        //console.log("token: ", token);
        //const session = await Auth.currentSession();
        //const token = getAuthHeader(session);
        const s3UrlStart = 'https://skye-app.s3.amazonaws.com/';
		//console.log("intercept: ", req.url)
        if (token && !req.url.startsWith(s3UrlStart)) {

            //console.log("token: ", token);

            
            const newReq = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + token)
            });
            
            return next.handle(newReq).pipe(catchError((err: any) => {
                if (err instanceof HttpErrorResponse && err.status === 401) {
                    this.securityService.resetUser();
                    //console.log("interceptor nav to login 1: ", err);
                    this.router.navigate(['/login'], {
                        queryParams: {
                            returnUrl: document.location.pathname
                        }
                    });

                    // this response is handled stop the chain of handlers by returning empty
                    return EMPTY;
                }

                // rethrow so other error handlers may pick this up
                return observableThrowError(err);
            }));
        } else {
			console.log("other: ", req.url)
            return next.handle(req).pipe(catchError((err: any) => {
                if (err instanceof HttpErrorResponse && err.status === 401) {
                    //console.log("interceptor nav to login 2")
                    this.router.navigate(['/login'], {
                        queryParams: {
                            returnUrl: document.location.pathname
                        }
                    });

                    // this response is handled stop the chain of handlers by returning empty
                    return EMPTY;
                }

                // rethrow so other error handlers may pick this up
                return observableThrowError(err);
            }));
        }
    }
}

@NgModule({
    providers: [{
        provide: HTTP_INTERCEPTORS,
        useClass: HttpRequestInterceptor,
        multi: true
    }]
})
export class HttpInterceptorModule { }
