import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EquipmentService } from 'src/app/equipment/equipment.service';
import { VehicleService } from 'src/app/vehicle/vehicle.service';
import { UpDialogComponent, UpDialogRef } from '@ellira/upstrap-dialog';
import { GeoTabService, GeotabRatePlan } from '../../geotab/geotab.service';
import { VehicleModel } from 'src/app/vehicle/vehicle-models';
import { IAlertOptions, UpAlertService } from '@ellira/upstrap-alert';
import { SecurityService } from '../../security/security.service';
import { AppUserAuth } from '../../security/app-user-auth';

export interface ChangeGeotabRatePlanData {
}

@Component({
    templateUrl: './change-geotab-rate-plan-dialog.component.html'
})

export class ChangeGeotabRatePlanDialogComponent extends UpDialogComponent<ChangeGeotabRatePlanData> implements OnInit {
    data: any;
    formReady = false;
    detailForm: FormGroup;
	geotabRatePlans: GeotabRatePlan[] = [];
    user: AppUserAuth = null;
	securityGroups: any;

	@ViewChild('alerts') private alerts;

	@HostListener('document:keydown', ['$event'])
    onKeydown(e) {
        if (e.key == "Escape" || e.code == "Escape") {
            this.onClose();
        }
    }

    constructor(public dialogRef: UpDialogRef<ChangeGeotabRatePlanDialogComponent>,
        private fb: FormBuilder,
        private vehicleService: VehicleService,
        private equipmentService: EquipmentService,
        private geotabService: GeoTabService,
		private securityService: SecurityService,
		private alertService: UpAlertService,
        public sharedService: SharedService) {
        super();
    }

    ngOnInit() {
		this.user = this.securityService.user;
        this.formReady = false;
        this.init();
    }

    async init() {
		console.log("data: ", this.data);
		this.securityGroups = this.data.data.securityGroupId;
		console.log("securityGroups: ", this.securityGroups)
		this.geotabRatePlans = this.data.geotabRatePlans.filter(x => x.active).sort((a, b) => a.displayOrder - b.displayOrder).map(plan => ({
			...plan, 
			formattedPrice: `$${plan.monthlyPrice.toFixed(2)} / month` // Add new formattedPrice property
		}));
        this.detailForm = this.fb.group({
			currentGeotabDevicePlanId: [this.data.data.geotabDevicePlanId],
            geotabDevicePlanId: [this.data.data.geotabDevicePlanId, [Validators.required]]
        });
       
        setTimeout(() => {
            this.formReady = true;
        }, 500);
        
    }

    save() {
		this.dialogRef.close(this.data, 'reload');
		var patchDoc = [{ 'op': 'replace', 'path': '/' + 'geotabDevicePlanId', 'value': this.detailForm.get('geotabDevicePlanId').value },
			{ 'op': 'replace', 'path': '/' + 'currentGeotabDevicePlanId', 'value': this.detailForm.get('currentGeotabDevicePlanId').value }];
		this.vehicleService.patch(this.sharedService.vehiclesApiUrl + 'vehicles/' + this.data.data.vehicleId, patchDoc).subscribe(
			resp => {
				console.log("resp: ", resp);
				this.data.data.geotabDevicePlanId = this.detailForm.get('geotabDevicePlanId').value;
				this.dialogRef.close(this.data, 'reload');
			},
			error => {
				console.log("error: ", error);
				this.createAlert(null, error.message ? error.message : "Error saving Geotab Rate Plan");
			}
		)
		

    }

	onClose() {
		console.log("onClose: ", this.data);
        this.dialogRef.close(this.data, 'cancel');
    }

    // Alert:
    createAlert(title, message) {
        const options: IAlertOptions = {
            title: title,
            content: message,
            dismissible: true,
            duration: 5,
            color: 'danger',
            alertError: true
        }
        this.alertService.show(this.alerts, options);
    }

}
