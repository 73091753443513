import { Component, Input, OnInit, ComponentFactoryResolver } from '@angular/core';
import { GeofenceDetailDialogComponent } from './detail/geofence-detail-dialog.component';
import { Subscription } from 'rxjs';
import { GeoTabService } from '../geotab.service';
import { UpDialogService } from '@ellira/upstrap-dialog';

declare const google: any;

@Component({
    templateUrl: './geofence.component.html',
    selector: 'up-geofence'
})
export class GeofenceComponent implements OnInit {

    @Input() noCard: any;

    map: any;
    geofenceComplete: boolean = false;
    geofence: any;
    geoline: any;
    markerArray: any;
    geofenceReady: boolean = false;
    zoneId = { value: null };
    dialogSub: Subscription;
    zoneName: string;
    zoneTypeId: string;
    allZones: any;
    zoneInfoWindow: any;
    identifier = `up-geofence-${++identifier}`;

    constructor(
        private dialogService: UpDialogService<GeofenceDetailDialogComponent>,
        private geoTabService: GeoTabService,
        private resolver: ComponentFactoryResolver) {
    }

    ngOnInit() {
        if (this.noCard === '' || this.noCard === 'true') {
            this.noCard = true;
        }
    }

    public setZoneName(name) {
        this.zoneName = name;
    }

    public async setZoneTypeIdByName(zoneName) {
        if (this.geoTabService.zoneList != undefined && this.geoTabService.zoneList != null) {
            this.setZoneTypeByName(zoneName, this.geoTabService.zoneList);
        } else {
            const zoneListReady = await this.geoTabService.loadZoneListData();
            if (zoneListReady) {
                this.setZoneTypeByName(zoneName, this.geoTabService.zoneList);
            }
        }
    }

    setZoneTypeByName(zoneName, zoneList) {
        zoneList.forEach(zone => {
            if (zone.name == zoneName) {
                this.zoneTypeId = zone.id;
            }
        })
    }

    public isVisible() {
        return this.geofenceReady;
    }

    public clearGeofence(disableMap?) {
        if (disableMap) {
            this.geofenceReady = false;
        }
        this.geofenceComplete = false;
        if (this.geofence != undefined) {
            this.geofence.setMap(null);
        }
        if (this.geoline != undefined) {
            this.geoline.setMap(null);
        }
        this.geoline = new google.maps.Polyline({
            strokeColor: '#DF2021',
            strokeOpacity: 1.0,
            strokeWeight: 3
        });
        this.geoline.setMap(this.map);

        if (this.markerArray != undefined) {
            this.markerArray.forEach(marker => {
                marker.setMap(null);
            });
            this.markerArray.length = 0;
        }
    }

    public showGeoMap() {
        this.geofenceReady = true;
    }

    public createGeoMap(lon, lat, locationLoading, geofenceCoords?, hideOnLoad?) {
        if (hideOnLoad == null || hideOnLoad == undefined || hideOnLoad == false) { this.geofenceReady = true; }
        setTimeout(() => {
            // Create map:
            let mapProp = {
                center: new google.maps.LatLng(lat, lon),
                zoom: 16,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                draggableCursor: 'default',
                draggingCursor: 'pointer',
            };
            this.map = new google.maps.Map(document.getElementById(this.identifier), mapProp);

            // Create location marker:
            new google.maps.Marker({
                position: new google.maps.LatLng(lat, lon),
                map: this.map,
            });

            if (geofenceCoords) {
                // Create existing geofence:
                this.geofenceComplete = true;
                this.geofence = new google.maps.Polygon({
                    paths: geofenceCoords,
                    strokeColor: '#DF2021',
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    fillColor: '#DF2021',
                    fillOpacity: 0.5,
                    title: this.zoneName ? this.zoneName : null
                });
                this.geofence.setMap(this.map);
                // Create click event for polygon (to edit details):
                const data = { data: { zoneName: this.zoneName, zoneTypeId: this.zoneTypeId } }
                this.geofence.addListener('click', this.openGeofenceDetailDialog.bind(this, data));
            } else {
                // Create clickable geofence path (geoline):
                this.geoline = new google.maps.Polyline({
                    strokeColor: '#DF2021',
                    strokeOpacity: 1.0,
                    strokeWeight: 3
                });
                this.geoline.setMap(this.map);
            }

            // Click event for creating geofence:
            this.map.addListener('click', this.addPoint.bind(this));

            this.map.addListener('tilesloaded', this.getAllZones.bind(this));

            locationLoading.value = false;
        }, 100);
    }

    async getAllZones() {
        if (this.allZones != undefined && this.allZones.length) {
            this.allZones.forEach(zone => {
                zone.setMap(null);
            });
        }
        const viewport = { x: this.map.getBounds().getSouthWest().lng(), y: this.map.getBounds().getNorthEast().lat(), width: this.map.getBounds().getNorthEast().lng() - this.map.getBounds().getSouthWest().lng(), height: this.map.getBounds().getSouthWest().lat() - this.map.getBounds().getNorthEast().lat() };
        const hasViewports = await this.geoTabService.getZoneInViewport(viewport);

        if (hasViewports) {
            this.allZones = [];
            this.geoTabService.allZones.forEach(zone => {
                // Fix coordinates
                let coords = [];
                let maxY = -90;
                let minY = 90;
                let maxX = -180;
                let minX = 180;
                zone.points.forEach((point, index) => {
                    coords.push({ lat: point.y, lng: point.x });
                    maxY = Math.max(maxY, point.y);
                    maxX = Math.max(maxX, point.x);
                    minY = Math.min(minY, point.y);
                    minX = Math.min(minX, point.x);

                    if (index == zone.points.length - 1) {
                        const zoneOutline = new google.maps.Polygon({
                            paths: coords,
                            strokeColor: '#353537',
                            strokeOpacity: 0.5,
                            strokeWeight: 2,
                            fillColor: '#353537',
                            fillOpacity: 0.25,
                            title: zone.name
                        });
                        zoneOutline.setMap(this.map);

                        zoneOutline.addListener('click', this.clickZone.bind(this, zone.name, { lat: minY + ((maxY - minY) / 2), lng: minX + ((maxX - minX) / 2) }));
                        this.allZones.push(zoneOutline);
                    }
                });
            })
        }
    }

    clickZone(zoneDetails, center) {
        if (this.zoneInfoWindow) {
            this.zoneInfoWindow.close();
        }
        this.zoneInfoWindow = new google.maps.InfoWindow({
            content: zoneDetails,
            position: center
        });
        this.zoneInfoWindow.open(this.map);
    }

    addPoint(event) {
        if (this.geofenceComplete) return;
        var path = this.geoline.getPath();

        // Because path is an MVCArray, we can simply append a new coordinate
        // and it will automatically appear.
        path.push(event.latLng);

        // Add a new marker at the new plotted point on the polyline.
        var marker = new google.maps.Marker({
            position: event.latLng,
            map: this.map,
            icon: {
                path: google.maps.SymbolPath.CIRCLE,
                fillColor: '#DF2021',
                fillOpacity: 1,
                strokeColor: '#DF2021',
                strokeOpacity: 1,
                strokeWeight: 1,
                scale: 7
            }
        });
        if (path.length == 1) {
            this.markerArray = [];
            const data = { data: { zoneName: this.zoneName, zoneTypeId: this.zoneTypeId } }
            marker.addListener('click', this.openGeofenceDetailDialog.bind(this, data));
        }
        this.markerArray.push(marker);
    }

    openGeofenceDetailDialog(data) {
        if (this.dialogService.isOpen()) { return; }
        this.dialogService.open(GeofenceDetailDialogComponent, data, this.resolver);
        this.dialogSub = this.dialogService.afterClose().subscribe((d) => {
            if (d.dialogStatus == 'save') {
                this.zoneName = d.zoneName;
                this.zoneTypeId = d.zoneTypeId;
                if (!this.geofenceComplete) {
                    this.completeGeofence();
                }
            } else if (d.dialogStatus == 'clear') {
                this.clearGeofence();
            }
            // Unsubscribe to prevent multiple subscriptions:
            this.dialogSub.unsubscribe();
        });
    }

    completeGeofence() {
        this.geofenceComplete = true;
        this.geofence = new google.maps.Polygon({
            paths: this.geoline.getPath(),
            strokeColor: '#DF2021',
            strokeOpacity: 1,
            strokeWeight: 2,
            fillColor: '#DF2021',
            fillOpacity: 0.5,
            title: this.zoneName ? this.zoneName : null
        });
        this.geofence.setMap(this.map);
        // Create click event for polygon (to edit details):
        const data = { data: { zoneName: this.zoneName, zoneTypeId: this.zoneTypeId } }
        this.geofence.addListener('click', this.openGeofenceDetailDialog.bind(this, data));
        this.geoline.setMap(null);
        this.markerArray.forEach(marker => {
            marker.setMap(null);
        });
    }

    public async saveZone(): Promise<boolean> {
        if (!this.geofenceComplete) {
            if (this.zoneId.value != undefined) {
                // Delete existing GeoTab Zone:
                this.geoTabService.deleteZone(this.zoneId.value);
                this.zoneId.value = null;
            }
            return true;
        } else {
            const path = this.geofence.getPath();
            let coords = [];
            for (var i = 0; i < path.length; i++) {
                coords.push([
                    path.getAt(i).lng(),
                    path.getAt(i).lat()
                ]);
            }
            if (this.geoTabService.zone != undefined) {
                // Save existing zone:
                this.geoTabService.zone.name = this.zoneName;
                this.geoTabService.zone.zoneTypes[0].id = this.zoneTypeId;

                return this.geoTabService.saveZone(coords, this.zoneName, this.zoneId, this.zoneTypeId, true);
                //this.geoTabService.zone.points = coords;
            } else {
                return this.geoTabService.saveZone(coords, this.zoneName, this.zoneId, this.zoneTypeId);
            }

        }
    }

    public deleteZone(zoneId?) {
        if (zoneId) {
            this.geoTabService.deleteZone(zoneId);
        } else if (this.zoneId.value) {
            this.geoTabService.deleteZone(this.zoneId.value);
        }
    }

    public getZoneId() {
        return this.zoneId.value;
    }

    public async loadZone(zoneId, long, lat, locationLoading) {
        const zoneLoaded = await this.geoTabService.loadZone(zoneId);
        if (zoneLoaded) {
            this.geofenceComplete = true;
            // Fix coordinates format:
            let coords = [];
            this.geoTabService.zone.points.forEach((point, index) => {
                coords.push({ lat: point.y, lng: point.x });
                if (index == this.geoTabService.zone.points.length - 1) {
                    this.zoneName = this.geoTabService.zone.name;
                    this.zoneTypeId = this.geoTabService.zone.zoneTypes[0].id;
                    this.zoneId.value = zoneId;
                    this.createGeoMap(long, lat, locationLoading, coords, true);
                }
            })
        }
    }
}

let identifier = 0;