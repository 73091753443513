import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorPageComponent } from './error-page.component';
import { UpstrapCoreModule } from '@ellira/upstrap-core';
import { UpstrapCardModule } from '@ellira/upstrap-card';
import { UpstrapButtonModule } from '@ellira/upstrap-button';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '404',
        component: ErrorPageComponent
    }
]

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        UpstrapCoreModule,
        UpstrapCardModule,
        UpstrapButtonModule
    ],
    declarations: [
        ErrorPageComponent
    ],
    exports: [
        ErrorPageComponent
    ],
})
export class ErrorPageModule { }
