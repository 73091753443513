import { Component, OnInit, ChangeDetectorRef, HostListener } from '@angular/core';
import { AppUserAuth } from 'src/app/shared/security/app-user-auth';
import { Subscription } from 'rxjs';
import { SecurityService } from 'src/app/shared/security/security.service';
import { UserService } from '../../user.service';
import { UpDialogComponent, UpDialogRef } from '@ellira/upstrap-dialog';
import { SharedService } from 'src/app/shared/shared.service';

export interface StartupScreenData {
    startupScreen: string;
}

@Component({
    templateUrl: './startup-screen-dialog.component.html'
})
export class StartupScreenDialogComponent extends UpDialogComponent<StartupScreenData> implements OnInit {
    data: any;
    user: AppUserAuth;
    dialogSub: Subscription;

    formReady = false;

    startupScreen: string;


    startPages: Array<any> = new Array<any>();

    @HostListener('document:keydown', ['$event'])
    onKeydown(e) {
        if (e.key == "Escape" || e.code == "Escape") {
            this.onClose();
        }
    }

    constructor(public dialogRef: UpDialogRef<StartupScreenDialogComponent>,
        private security: SecurityService,
        private ds: UserService,
        private sharedService: SharedService,
        private changeDetecter: ChangeDetectorRef) {
        super();
    }

    ngOnInit() {
        this.user = this.security.user;
    
        this.startPages.push({ id: 'Employee', text: 'Employee' });
        this.startPages.push({ id: 'Equipment', text: 'Equipment' });
        this.startPages.push({ id: 'Tasks', text: 'Tasks' });
        //this.startPages.push({ id: 'Schedule', text: 'Schedule' });
        this.startPages.push({ id: 'Trailers', text: 'Trailers' });
        this.startPages.push({ id: 'Vehicles', text: 'Vehicles' });


        Promise.resolve(null).then(() => {
            this.initialize();
        });
    }

    async initialize() {

        this.startupScreen = this.user.claimValue('startupScreen');

        this.changeDetecter.detectChanges();
        this.formReady = true;
    }

    onClose() {
        this.dialogRef.close(this.data, 'cancel');
    }

    save() {
        const userId = +this.user.claimValue('userId')
        this.ds.patch(this.sharedService.adminApiUrl + '/users/' + userId, [{ 'op': 'replace', 'path': '/' + 'startupScreen', 'value': this.startupScreen }]).subscribe(
            resp => {
                this.user.claims.filter(x => x.type == "startupScreen")[0].value = this.startupScreen;
                this.dialogRef.close(this.data, 'save');
            },
            error => {
                console.log("error");
            }
        )
    }
}
