import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { GeoTabAuthentication, GeoTabTripPoint, GeoTabLogRecord, GeotabDevicePlanList } from './geotab';
// import entire SDK
import { Observable } from 'rxjs';
import { HttpResponse, HttpClient } from '@angular/common/http';
import { SharedService } from '../shared.service';
import { GeotabRatePlanHistoryList } from './rate-plan-history/geotab-rate-plan-history-models';
import { IDriver } from 'src/app/drivers/drivers';

export interface GeotabRatePlan {
	id: number;
	level: number;
	name: string;
	validForOrder: boolean;
}

export interface GeotabChangeRatePlan {
	id: number;
	serialNumber: string;
}

@Pipe({
    name: 'geoTabTimeSpanPipe'
})
export class GeoTabTimeSpanPipe implements PipeTransform {

    transform(time: string): string {
        if (!time) { return time }
        if (time == '00:00:00') { return '' }

        let dd;
        let hh;
        let mm;
        let ss;
        // [-]
        if (time.charAt(0) == '-') {
            time = time.substr(1);
        }
        // [dd.]
        if (time.indexOf('.') != -1 && time.indexOf('.') < time.indexOf(':')) {
            dd = time.substring(0, time.indexOf('.'));
            time = time.substring(time.indexOf('.') + 1);
        }
        // remove [.hhhh]
        if (time.indexOf('.') != -1) {
            time = time.substring(0, time.indexOf('.'));
        }
        const timeArray = time.split(":");
        if (timeArray.length != 3) {
            return time;
        }

        hh = timeArray[0].replace(/^0+/, '');
        mm = timeArray[1].replace(/^0+/, '');
        ss = timeArray[2].replace(/^0+/, '');

        let finalTime = '';
        if (dd) {
            finalTime = dd + ' d ';
        }
        if (hh && hh != '') {
            finalTime = finalTime + hh + ' h ';
        }
        if (mm && mm != '') {
            finalTime = finalTime + mm + ' m ';
        }
        /* if (ss && ss != '') {
            finalTime = finalTime + ss + ' s ';
        } */

        return finalTime.replace(/\s*$/, '');
    }
}


@Injectable()
export class GeoTabService {

    private geoAuth: GeoTabAuthentication;
    public deviceList: any;
    public tripData: GeoTabTripPoint[];
    public logRecord: GeoTabLogRecord[];
    public device: any;
    public odometer: string;
    public engineHours: string;
    public zone;
    public zoneList: any;
    public allZones: any;
    public deviceStatusInfo: any;
    public deviceLogRecord: any;
    public deviceLocationInfo: any;
    public groups: any;

    constructor(
        private http: HttpClient,
        public sharedService: SharedService,
    ) { }

	
	getDevicePlans(): Observable<HttpResponse<GeotabDevicePlanList>> {
		console.log("getDevicePlans, vehicleAPI: ", this.sharedService.vehiclesApiUrl)
		return this.http.get<GeotabDevicePlanList>(this.sharedService.vehiclesApiUrl + 'geotabdeviceplans', { observe: 'response' });
	}

	getDrivers(): Observable<HttpResponse<IDriver[]>> {
		return this.http.get<IDriver[]>(this.sharedService.adminApiUrl + 'geotabdrivers', { observe: 'response' });
	}

	changeRatePlan(ratePlan: GeotabChangeRatePlan): Observable<HttpResponse<GeotabChangeRatePlan>> {
		return this.http.post<GeotabChangeRatePlan>(this.sharedService.adminApiUrl + 'geotabchangerateplan', ratePlan, { observe: 'response' });
	}

	getRatePlanHistoryList(params?: any): Observable<HttpResponse<GeotabRatePlanHistoryList>> {
		return this.http.get<GeotabRatePlanHistoryList>(params != undefined && Object.keys(params).length != 0 ? this.sharedService.vehiclesApiUrl + 'geotabrateplanhistories' + '?' + this.sharedService.param(params) : this.sharedService.vehiclesApiUrl + 'geotabrateplanhistories', { observe: 'response' });
	}



	// Old Service Methods - to be replaced by methods accessing our API and Database directly:

    getAuthCredentials(): Observable<HttpResponse<any>> {
        return this.http.get<any>(this.sharedService.adminApiUrl + 'geotabtokens', { observe: 'response' });
    }

    authenticateGeoTab(): Promise<boolean> {
        console.log("authenticateGeoTab()")
        if (this.geoAuth != undefined) {
			console.log("has auth, resolve")
            return new Promise((resolve) => { resolve(true) });
        }

        return new Promise((resolve, reject) => {
            this.getAuthCredentials().subscribe(
                resp => {
                    console.log("getAuthCredentials resp: ", resp)
					if (resp.body && resp.body.path) {
						this.geoAuth = resp.body;
						resolve(true);
					} else {
						console.log("no path, resolve false")
						reject()
					}
                },
                error => {
                    console.log("getAuthCredentials error: ", error);
                    reject()
                }
            )
        });
    }

    getDriversOld(onlyActive?: boolean): Promise<any> {
		console.log("getDrivers()")
        return new Promise(async (resolve, reject) => {


        const isAuth = this.geoAuth != undefined || await this.authenticateGeoTab();

        if (isAuth) {
			console.log("isAuth")
            var request = new XMLHttpRequest();

            request.onreadystatechange = (resp) => {
                if ((<XMLHttpRequest>resp.currentTarget).readyState === 4 && (<XMLHttpRequest>resp.currentTarget).status === 200) {
                    var json = JSON.parse((<XMLHttpRequest>resp.currentTarget).responseText);
                    //console.log("json: ", json);
                    if (json && json.result.length > 0) {
                        let drivers = [];
                        json.result.filter(x => x.isDriver).forEach(d => {
                            if (!onlyActive) {
                                drivers.push({ driverName: d.firstName + ' ' + d.lastName, firstName: d.firstName, lastName: d.lastName, driverId: d.id, activeTo: d.activeTo, lastFirst: d.lastName + ', ' + d.firstName })
                            } else if (onlyActive && !(new Date(d.activeTo) < new Date())) {
                                drivers.push({ driverName: d.firstName + ' ' + d.lastName, firstName: d.firstName, lastName: d.lastName, driverId: d.id, activeTo: d.activeTo, lastFirst: d.lastName + ', ' + d.firstName })
                            }
                        })
                        drivers.sort((x, y) => x.lastFirst.localeCompare(y.lastFirst));
                        resolve(drivers);
                    } else {
                        resolve(false);
                    }
                }

                // TODO: Handle errors on credential expiry / db move (re-authenticate)
            }

            request.open("POST", this.geoAuth.path == 'ThisServer' ? 'https://my.geotab.com/apiv1' : 'https://' + this.geoAuth.path + '/apiv1', true);
            request.setRequestHeader("Content-Type", "application/json");

			console.log("request set header")

            var data = JSON.stringify({

                "method": "Get",
                "params": {
                    "typeName": "User",
                    "credentials": this.geoAuth.credentials
                }
            });

            request.send(data);
        }
        });

    }

    /*  Device: */

    loadDeviceList(): Promise<boolean> {
        console.log("loadDeviceList: ", this.geoAuth)
        return new Promise((resolve, reject) => {
            var request = new XMLHttpRequest();

            request.onreadystatechange = (resp) => {
                if ((<XMLHttpRequest>resp.currentTarget).readyState === 4 && (<XMLHttpRequest>resp.currentTarget).status === 200) {
                    var json = JSON.parse((<XMLHttpRequest>resp.currentTarget).responseText);
                    if (json) {
                        this.deviceList = json.result;
                        //console.log("devices: ", this.deviceList);
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                }

                // TODO: Handle errors on credential expiry / db move (re-authenticate)
            }

            request.open("POST", this.geoAuth.path == 'ThisServer' ? 'https://my.geotab.com/apiv1' : 'https://' + this.geoAuth.path + '/apiv1', true);
            request.setRequestHeader("Content-Type", "application/json");


            var data = JSON.stringify({

                "method": "Get",
                "params": {
                    "typeName": "Device",
                    "credentials": this.geoAuth.credentials
                }
            });

            request.send(data);
        });
    }

    getDeviceById(deviceId): Promise<any> {
        var data = JSON.stringify({
            "method": "Get",
            "params": {
                "typeName": "Device",
                "credentials": this.geoAuth.credentials,
                "search": {
                    "id": deviceId
                }
            }
        });

        var request = new XMLHttpRequest();

        return new Promise((resolve, reject) => {
            request.onreadystatechange = (resp) => {
                if ((<XMLHttpRequest>resp.currentTarget).readyState === 4 && (<XMLHttpRequest>resp.currentTarget).status === 200) {
                    var json = JSON.parse((<XMLHttpRequest>resp.currentTarget).responseText);
                    if (json && json.result.length) {
                        resolve(json.result[0]);
                    } else {
                        console.log("error, no result for get device: ", deviceId, " api resp: ", resp);
                        resolve(false);
                    }
                }
                // TODO: Handle errors on credential expiry / db move (re-authenticate)
            }

            request.open("POST", this.geoAuth.path == 'ThisServer' ? 'https://my.geotab.com/apiv1' : 'https://' + this.geoAuth.path + '/apiv1', true);
            request.setRequestHeader("Content-Type", "application/json");

            request.send(data);
        });
    }

    saveDevice(device): Promise<boolean> {
        var data = JSON.stringify({
            "method": "Set",
            "params": {
                "typeName": "Device",
                "credentials": this.geoAuth.credentials,
                "entity": device
            }
        });


        var request = new XMLHttpRequest();

        return new Promise((resolve, reject) => {
            request.onreadystatechange = (resp) => {
                if ((<XMLHttpRequest>resp.currentTarget).readyState === 4 && (<XMLHttpRequest>resp.currentTarget).status === 200) {
                    //var json = JSON.parse((<XMLHttpRequest>resp.currentTarget).responseText);
                    this.loadDeviceList();
                }
                // TODO: Handle errors on credential expiry / db move (re-authenticate)
            }

            request.open("POST", this.geoAuth.path == 'ThisServer' ? 'https://my.geotab.com/apiv1' : 'https://' + this.geoAuth.path + '/apiv1', true);
            request.setRequestHeader("Content-Type", "application/json");

            request.send(data);
        });
    }

    async saveNewDevice(deviceDescription, deviceSerialNumber): Promise<number> {

        const isAuth = this.geoAuth != undefined || await this.authenticateGeoTab();

        if (isAuth) {
            var data;
            let saveDevice = {
                serialNumber: deviceSerialNumber,
                name: deviceDescription
            }
            //console.log("saveDevice: ", saveDevice);
            data = JSON.stringify({
                "method": "Add",
                "params": {
                    "typeName": "Device",
                    "credentials": this.geoAuth.credentials,
                    "entity": saveDevice
                }
            });

            var request = new XMLHttpRequest();

            return new Promise<number>(resolve => {
                request.onreadystatechange = (resp) => {
                    if ((<XMLHttpRequest>resp.currentTarget).readyState === 4 && (<XMLHttpRequest>resp.currentTarget).status === 200) {
                        var json = JSON.parse((<XMLHttpRequest>resp.currentTarget).responseText);
                        if (json && json.result != undefined) {
                            //zoneRef.value = json.result
                            resolve(json.result);
                        } else {
                            console.log("error, device not saved: ", json);
                            resolve(-1);
                        }
                    }
                    // TODO: Handle errors on credential expiry / db move (re-authenticate)
                }

                request.open("POST", this.geoAuth.path == 'ThisServer' ? 'https://my.geotab.com/apiv1' : 'https://' + this.geoAuth.path + '/apiv1', true);
                request.setRequestHeader("Content-Type", "application/json");

                request.send(data);
            });
        } else {
            console.log("ERROR: GeoTab authentication failure");
        }
    }


    /* Additional Device Data */

    getDeviceStatusInfo(deviceId): Promise<boolean> {
        return new Promise((resolve, reject) => {
            var request = new XMLHttpRequest();

            request.onreadystatechange = (resp) => {
                if ((<XMLHttpRequest>resp.currentTarget).readyState === 4 && (<XMLHttpRequest>resp.currentTarget).status === 200) {
                    var json = JSON.parse((<XMLHttpRequest>resp.currentTarget).responseText);
                    if (json && json.result.length) {
                        this.deviceStatusInfo = json.result[0];
                        resolve(true);
                        //this.showMap(json.result[0].latitude, json.result[0].longitude);
                    } else {
                        console.log("error, no result for getDeviceStatusInfo, device: ", deviceId, " api resp: ", resp);
                        resolve(false);
                    }
                }

                // TODO: Handle errors on credential expiry / db move (re-authenticate)
            }

            request.open("POST", this.geoAuth.path == 'ThisServer' ? 'https://my.geotab.com/apiv1' : 'https://' + this.geoAuth.path + '/apiv1', true);
            request.setRequestHeader("Content-Type", "application/json");

            var data = JSON.stringify({
                "method": "Get",
                "params": {
                    "typeName": "DeviceStatusInfo",
                    "credentials": this.geoAuth.credentials,
                    "search": {
                        "deviceSearch": {
                            "id": deviceId
                        }
                    }
                }
            });

            request.send(data);
        });
    }

    getDriverLogs(driverId, startDate, endDate): Promise<any> {
        var fromDate = new Date(startDate);
        var toDate = new Date(endDate);

        return new Promise((resolve, reject) => {
            var request = new XMLHttpRequest();

            request.onreadystatechange = (resp) => {
                if ((<XMLHttpRequest>resp.currentTarget).readyState === 4 && (<XMLHttpRequest>resp.currentTarget).status === 200) {
                    var json = JSON.parse((<XMLHttpRequest>resp.currentTarget).responseText);
                    if (json && json.result.length) {
                        this.deviceLogRecord = json.result[0];
                        //this.deviceStatusInfo = json.result[0];

                        resolve(json.result);
                        //this.showMap(json.result[0].latitude, json.result[0].longitude);
                    } else {
                        console.log("error, no result for getDriverLogs, device: ", driverId, " api resp: ", resp);
                        resolve(false);
                    }
                }

                // TODO: Handle errors on credential expiry / db move (re-authenticate)
            }

            request.open("POST", this.geoAuth.path == 'ThisServer' ? 'https://my.geotab.com/apiv1' : 'https://' + this.geoAuth.path + '/apiv1', true);
            request.setRequestHeader("Content-Type", "application/json");



            var data = JSON.stringify({
                "method": "Get",
                "params": {
                    "typeName": "DriverChange",
                    "credentials": this.geoAuth.credentials,
                    "search": {
                        "userSearch": {
                            "id": driverId
                        },
                        //"fromDate": fromDate,
                        //"toDate": toDate
                    }
                }
            });

            request.send(data);
        });
    }

    getDriverChange(deviceId, startDate, endDate): Promise<any> {
        var fromDate = new Date(startDate);
        var toDate = new Date(endDate);

        return new Promise((resolve, reject) => {
            var request = new XMLHttpRequest();

            request.onreadystatechange = (resp) => {
                if ((<XMLHttpRequest>resp.currentTarget).readyState === 4 && (<XMLHttpRequest>resp.currentTarget).status === 200) {
                    var json = JSON.parse((<XMLHttpRequest>resp.currentTarget).responseText);
                    if (json && json.result.length) {
                        this.deviceLogRecord = json.result[0];
                        //this.deviceStatusInfo = json.result[0];

                        resolve(json.result);
                        //this.showMap(json.result[0].latitude, json.result[0].longitude);
                    } else {
                        console.log("error, no result for getLocagetDeviceLogRecordtion, device: ", deviceId, " api resp: ", resp);
                        resolve(false);
                    }
                }

                // TODO: Handle errors on credential expiry / db move (re-authenticate)
            }

            request.open("POST", this.geoAuth.path == 'ThisServer' ? 'https://my.geotab.com/apiv1' : 'https://' + this.geoAuth.path + '/apiv1', true);
            request.setRequestHeader("Content-Type", "application/json");



            var data = JSON.stringify({
                "method": "Get",
                "params": {
                    "typeName": "DriverChange",
                    "credentials": this.geoAuth.credentials,
                    "search": {
                        "deviceSearch": {
                            "id": deviceId
                        },
                        "fromDate": fromDate,
                        "toDate": toDate
                    }
                }
            });

            request.send(data);
        });
    }

    getDeviceLogRecord(deviceId): Promise<boolean> {
        var fromDate = new Date();
        var toDate = new Date(fromDate);
        fromDate.setMinutes(toDate.getMinutes() - 10);

        return new Promise((resolve, reject) => {
            var request = new XMLHttpRequest();

            request.onreadystatechange = (resp) => {
                if ((<XMLHttpRequest>resp.currentTarget).readyState === 4 && (<XMLHttpRequest>resp.currentTarget).status === 200) {
                    var json = JSON.parse((<XMLHttpRequest>resp.currentTarget).responseText);
                    if (json && json.result.length) {
                        this.deviceLogRecord = json.result[0];
                        //this.deviceStatusInfo = json.result[0];

                        resolve(true);
                        //this.showMap(json.result[0].latitude, json.result[0].longitude);
                    } else {
                        console.log("error, no result for getLocagetDeviceLogRecordtion, device: ", deviceId, " api resp: ", resp);
                        resolve(false);
                    }
                }

                // TODO: Handle errors on credential expiry / db move (re-authenticate)
            }

            request.open("POST", this.geoAuth.path == 'ThisServer' ? 'https://my.geotab.com/apiv1' : 'https://' + this.geoAuth.path + '/apiv1', true);
            request.setRequestHeader("Content-Type", "application/json");



            var data = JSON.stringify({
                "method": "Get",
                "params": {
                    "typeName": "LogRecord",
                    "credentials": this.geoAuth.credentials,
                    "search": {
                        "deviceSearch": {
                            "id": deviceId
                        },
                        "fromDate": fromDate,
                        "toDate": toDate
                    }
                }
            });

            request.send(data);
        });
    }

    

    getMultipleDeviceExceptionEvents(deviceIds: any[], fromDate, toDate): Promise<any> {
        return new Promise((resolve, reject) => {
            var request = new XMLHttpRequest();

            request.onreadystatechange = (resp) => {
                if ((<XMLHttpRequest>resp.currentTarget).readyState === 4 && (<XMLHttpRequest>resp.currentTarget).status === 200) {
                    var json = JSON.parse((<XMLHttpRequest>resp.currentTarget).responseText);
                    if (json && json.result && json.result.length) {
                        //console.log("getDeviceExceptionEvents: ", json)
                        resolve(json.result);
                    } else {
                        resolve(true);
                    }
                }
            }

            request.open("POST", this.geoAuth.path == 'ThisServer' ? 'https://my.geotab.com/apiv1' : 'https://' + this.geoAuth.path + '/apiv1', true);
            request.setRequestHeader("Content-Type", "application/json");

            // Array of Device ID's:
            var callsData = [];
            for (let i = 0; i < deviceIds.length; i++) {
                const dId = deviceIds[i];
                callsData.push({
                    "method": "Get",
                    "params": {
                        "typeName": "ExceptionEvent",
                        "credentials": this.geoAuth.credentials,
                        "search": {
                            "deviceSearch": {
                                "id": dId
                            },
                            "fromDate": fromDate,
                            "toDate": toDate
                        }
                    }
                });
            }
            var data = JSON.stringify({
                "method": "ExecuteMultiCall", 
                "params": {
                    "calls": callsData
                }
            });

            request.send(data);
        });
    }



    getDeviceExceptionEvents(deviceId, fromDate, toDate, ruleId?): Promise<any> {
        return new Promise((resolve, reject) => {
            var request = new XMLHttpRequest();

            request.onreadystatechange = (resp) => {
                if ((<XMLHttpRequest>resp.currentTarget).readyState === 4 && (<XMLHttpRequest>resp.currentTarget).status === 200) {
                    var json = JSON.parse((<XMLHttpRequest>resp.currentTarget).responseText);
                    if (json && json.result && json.result.length) {
                        //console.log("getDeviceExceptionEvents: ", json)
                        resolve(json.result);
                    } else {
                        resolve(true);
                    }
                }
            }

            request.open("POST", this.geoAuth.path == 'ThisServer' ? 'https://my.geotab.com/apiv1' : 'https://' + this.geoAuth.path + '/apiv1', true);
            request.setRequestHeader("Content-Type", "application/json");

            var data = ruleId ? JSON.stringify({
                "method": "Get",
                "params": {
                    "typeName": "ExceptionEvent",
                    "credentials": this.geoAuth.credentials,
                    "search": {
                        "deviceSearch": {
                            "id": deviceId
                        },
                        "ruleSearch": {
                            "id": ruleId
                        },
                        "fromDate": fromDate,
                        "toDate": toDate
                    }
                }
            }) : JSON.stringify({
                "method": "Get",
                "params": {
                    "typeName": "ExceptionEvent",
                    "credentials": this.geoAuth.credentials,
                    "search": {
                        "deviceSearch": {
                            "id": deviceId
                        },
                        "fromDate": fromDate,
                        "toDate": toDate
                    }
                }
            });

            request.send(data);
        });
    }

    getDeviceLocationInfo(points): Promise<boolean> {
        var data = JSON.stringify({
            "method": "GetAddresses",
            "params": {
                "coordinates": points,
                "credentials": this.geoAuth.credentials,
            }
        });

        var request = new XMLHttpRequest();

        return new Promise((resolve, reject) => {
            request.onreadystatechange = (resp) => {
                if ((<XMLHttpRequest>resp.currentTarget).readyState === 4 && (<XMLHttpRequest>resp.currentTarget).status === 200) {
                    var json = JSON.parse((<XMLHttpRequest>resp.currentTarget).responseText);
                    if (json && json.result.length) {
                        this.deviceLocationInfo = json.result[0];
                        resolve(true);
                    } else {
                        console.log("error, no result for getDeviceLocationInfo, location: ", points, " api resp: ", resp);
                        resolve(false);
                    }
                }
                // TODO: Handle errors on credential expiry / db move (re-authenticate)
            }

            request.open("POST", this.geoAuth.path == 'ThisServer' ? 'https://my.geotab.com/apiv1' : 'https://' + this.geoAuth.path + '/apiv1', true);
            request.setRequestHeader("Content-Type", "application/json");

            request.send(data);
        });
    }

    async getDeviceDistance(deviceId, startDate, endDate): Promise<any> {
        console.log("getDeviceDistance: ", deviceId)
        const isAuth = this.geoAuth != undefined || await this.authenticateGeoTab();

        if (isAuth) {

            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 59, 999);
            var data;
            
            data = JSON.stringify({
                "method": "Get",
                "params": {
                    "typeName": "Trip",
                    "credentials": this.geoAuth.credentials,
                    "search": {
                        "deviceSearch": {
                            "id": deviceId
                        },
                        "fromDate": startDate,
                        "toDate": endDate
                    }
                }
            });
            

            var request = new XMLHttpRequest();

            return new Promise((resolve, reject) => {
                request.onreadystatechange = (resp) => {
                    if ((<XMLHttpRequest>resp.currentTarget).readyState === 4 && (<XMLHttpRequest>resp.currentTarget).status === 200) {
                        var json = JSON.parse((<XMLHttpRequest>resp.currentTarget).responseText);
                        if (json && json.result && json.result.length) {
                            resolve(json.result);
                        } else {
                            console.log("error, no result for getTripHistory, device: ", deviceId, " api resp: ", resp);
                            resolve(false);
                        }
                    }
                    // TODO: Handle errors on credential expiry / db move (re-authenticate)
                }

                request.open("POST", this.geoAuth.path == 'ThisServer' ? 'https://my.geotab.com/apiv1' : 'https://' + this.geoAuth.path + '/apiv1', true);
                request.setRequestHeader("Content-Type", "application/json");

                request.send(data);
            });
        } else {
            console.log("ERROR: GeoTab authentication failure");
        }
    }

    getDriverDistance(driverId, startDate, endDate): Promise<any> {
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);

        var data = JSON.stringify({
            "method": "Get",
            "params": {
                "typeName": "Trip",
                "credentials": this.geoAuth.credentials,
                "search": {
                    "userSearch": {
                        "id": driverId
                    },
                    "fromDate": startDate,
                    "toDate": endDate
                }
            }
        });

        var request = new XMLHttpRequest();

        return new Promise((resolve, reject) => {
            request.onreadystatechange = (resp) => {
                if ((<XMLHttpRequest>resp.currentTarget).readyState === 4 && (<XMLHttpRequest>resp.currentTarget).status === 200) {
                    var json = JSON.parse((<XMLHttpRequest>resp.currentTarget).responseText);
                    if (json && json.result && json.result.length) {
                        resolve(json.result);
                    } else {
                        //console.log("error, no result for getTripHistory, device: ", deviceId, " api resp: ", resp);
                        resolve(false);
                    }
                }
                // TODO: Handle errors on credential expiry / db move (re-authenticate)
            }

            request.open("POST", this.geoAuth.path == 'ThisServer' ? 'https://my.geotab.com/apiv1' : 'https://' + this.geoAuth.path + '/apiv1', true);
            request.setRequestHeader("Content-Type", "application/json");

            request.send(data);
        });
    }

    getDeviceTripData(deviceId, startDate): Promise<boolean> {
        startDate.setHours(0, 0, 0, 0);

        var endDate = new Date(startDate.getTime());
        endDate.setHours(23, 59, 59, 999);

        var data = JSON.stringify({
            "method": "Get",
            "params": {
                "typeName": "Trip",
                "credentials": this.geoAuth.credentials,
                "search": {
                    "deviceSearch": {
                        "id": deviceId
                    },
                    "fromDate": startDate,
                    "toDate": endDate
                }
            }
        });

        var request = new XMLHttpRequest();

        return new Promise((resolve, reject) => {
            request.onreadystatechange = (resp) => {
                if ((<XMLHttpRequest>resp.currentTarget).readyState === 4 && (<XMLHttpRequest>resp.currentTarget).status === 200) {
                    var json = JSON.parse((<XMLHttpRequest>resp.currentTarget).responseText);
                    if (json && json.result.length) {
                        this.tripData = json.result;
                        resolve(true);
                    } else {
                        console.log("error, no result for getTripHistory, device: ", deviceId, " api resp: ", resp);
                        resolve(false);
                    }
                }
                // TODO: Handle errors on credential expiry / db move (re-authenticate)
            }

            request.open("POST", this.geoAuth.path == 'ThisServer' ? 'https://my.geotab.com/apiv1' : 'https://' + this.geoAuth.path + '/apiv1', true);
            request.setRequestHeader("Content-Type", "application/json");

            request.send(data);
        });
    }

    getDeviceFuelData(deviceId, startDate, diagnosticType): Promise<any> {
        startDate.setHours(0, 0, 0, 0);

        var endDate = new Date(startDate.getTime());
        endDate.setHours(23, 59, 59, 999);

        var data = JSON.stringify({
            "method": "Get",
            "params": {
                "typeName": "StatusData",
                "credentials": this.geoAuth.credentials,
                "search": {
                    "diagnosticSearch": {
                        "id": diagnosticType
                    },
                    "deviceSearch": {
                        "id": deviceId
                    },
                    "fromDate": startDate,
                    "toDate": endDate
                }
            }
        });

        /*

        diagnosticDeviceTotalFuelId
        diagnosticDeviceTotalIdleFuelId

        diagnosticTotalFuelUsedId
        diagnosticTotalIdleFuelUsedId

        diagnosticTotalTripFuelUsedId *
        diagnosticTotalTripIdleFuelUsedId

        */

        var request = new XMLHttpRequest();

        return new Promise((resolve, reject) => {
            request.onreadystatechange = (resp) => {
                if ((<XMLHttpRequest>resp.currentTarget).readyState === 4 && (<XMLHttpRequest>resp.currentTarget).status === 200) {
                    var json = JSON.parse((<XMLHttpRequest>resp.currentTarget).responseText);
                    if (json && json.result) {
                        resolve(json.result);
                    } else {
                        console.log("error, no result for getDeviceFuelData, device: ", deviceId, " api resp: ", resp);
                        resolve(true);
                    }
                }
                // TODO: Handle errors on credential expiry / db move (re-authenticate)
            }

            request.open("POST", this.geoAuth.path == 'ThisServer' ? 'https://my.geotab.com/apiv1' : 'https://' + this.geoAuth.path + '/apiv1', true);
            request.setRequestHeader("Content-Type", "application/json");

            request.send(data);
        });
    }

    getDeviceLogRecordData(deviceId, startDate, endDate): Promise<boolean> {

        var data = JSON.stringify({
            "method": "Get",
            "params": {
                "typeName": "LogRecord",
                "credentials": this.geoAuth.credentials,
                "search": {
                    "deviceSearch": {
                        "id": deviceId
                    },
                    "fromDate": startDate,
                    "toDate": endDate
                }
            }
        });

        var request = new XMLHttpRequest();

        return new Promise((resolve, reject) => {
            request.onreadystatechange = (resp) => {
                if ((<XMLHttpRequest>resp.currentTarget).readyState === 4 && (<XMLHttpRequest>resp.currentTarget).status === 200) {
                    var json = JSON.parse((<XMLHttpRequest>resp.currentTarget).responseText);
                    if (json && json.result.length) {
                        this.logRecord = json.result;
                        resolve(true);
                    } else {
                        console.log("error, no result for getTripHistory, device: ", deviceId, " api resp: ", resp);
                        resolve(false);
                    }
                }
                // TODO: Handle errors on credential expiry / db move (re-authenticate)
            }

            request.open("POST", this.geoAuth.path == 'ThisServer' ? 'https://my.geotab.com/apiv1' : 'https://' + this.geoAuth.path + '/apiv1', true);
            request.setRequestHeader("Content-Type", "application/json");

            request.send(data);
        });
    }

    getDeviceRoadMaxSpeeds(deviceId, fromDate, toDate): Promise<any> {
        return new Promise((resolve, reject) => {
            var request = new XMLHttpRequest();

            request.onreadystatechange = (resp) => {
                if ((<XMLHttpRequest>resp.currentTarget).readyState === 4 && (<XMLHttpRequest>resp.currentTarget).status === 200) {
                    var json = JSON.parse((<XMLHttpRequest>resp.currentTarget).responseText);
                    if (json && json.result) {
                        resolve(json.result);
                    } else {
                        console.log("error, no result for getDeviceRoadMaxSpeeds, device: ", deviceId, " api resp: ", resp);
                        resolve(true);
                    }
                }

                // TODO: Handle errors on credential expiry / db move (re-authenticate)
            }

            request.open("POST", this.geoAuth.path == 'ThisServer' ? 'https://my.geotab.com/apiv1' : 'https://' + this.geoAuth.path + '/apiv1', true);
            request.setRequestHeader("Content-Type", "application/json");

            var data = JSON.stringify({
                "method": "GetRoadMaxSpeeds",
                "params": {
                    "credentials": this.geoAuth.credentials,
                    "deviceSearch": {
                        "id": deviceId
                    },
                    "fromDate": fromDate,
                    "toDate": toDate
                }
            });

            request.send(data);
        });
    }


    /* Groups:  */

    getGroups(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            var request = new XMLHttpRequest();

            request.onreadystatechange = (resp) => {
                if ((<XMLHttpRequest>resp.currentTarget).readyState === 4 && (<XMLHttpRequest>resp.currentTarget).status === 200) {
                    var json = JSON.parse((<XMLHttpRequest>resp.currentTarget).responseText);
                    if (json) {
                        //console.log("group: ", json.result)
                        //this.deviceList = json.result;
                        //resolve(true);
                        //const firstGroup = json.result.filter(x => x.id == "GroupCompanyId")[0];
                        this.groups = json.result;
                        /* this.createGroupHierarchy(firstGroup, json.result).then(data => {
                            resolve(true);
                        }) */
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                }

                // TODO: Handle errors on credential expiry / db move (re-authenticate)
            }

            request.open("POST", this.geoAuth.path == 'ThisServer' ? 'https://my.geotab.com/apiv1' : 'https://' + this.geoAuth.path + '/apiv1', true);
            request.setRequestHeader("Content-Type", "application/json");


            var data = JSON.stringify({

                "method": "Get",
                "params": {
                    "typeName": "Group",
                    "credentials": this.geoAuth.credentials
                }
            });

            request.send(data);
        });
    }

    getAllGroups(search?: any): Promise<any> {
        return new Promise((resolve, reject) => {
            var request = new XMLHttpRequest();

            request.onreadystatechange = (resp) => {
                if ((<XMLHttpRequest>resp.currentTarget).readyState === 4 && (<XMLHttpRequest>resp.currentTarget).status === 200) {
                    var json = JSON.parse((<XMLHttpRequest>resp.currentTarget).responseText);
                    if (json) {
                        resolve(json.result);
                    } else {
                        resolve(false);
                    }
                }

                // TODO: Handle errors on credential expiry / db move (re-authenticate)
            }

            request.open("POST", this.geoAuth.path == 'ThisServer' ? 'https://my.geotab.com/apiv1' : 'https://' + this.geoAuth.path + '/apiv1', true);
            request.setRequestHeader("Content-Type", "application/json");


            var data;

            if (search) {
                data = JSON.stringify({

                    "method": "Get",
                    "params": {
                        "typeName": "Group",
                        "credentials": this.geoAuth.credentials,
                        "search": search
                    }
                });
            } else {
                data = JSON.stringify({

                    "method": "Get",
                    "params": {
                        "typeName": "Group",
                        "credentials": this.geoAuth.credentials,
                    }
                });
            }

            request.send(data);
        });
    }

    getGroupById(groupId): Promise<any> {
        return new Promise((resolve, reject) => {
            var request = new XMLHttpRequest();

            request.onreadystatechange = (resp) => {
                if ((<XMLHttpRequest>resp.currentTarget).readyState === 4 && (<XMLHttpRequest>resp.currentTarget).status === 200) {
                    var json = JSON.parse((<XMLHttpRequest>resp.currentTarget).responseText);
                    if (json) {
                        //console.log("group: ", json.result)
                        //this.deviceList = json.result;
                        //resolve(true);
                        //const firstGroup = json.result.filter(x => x.id == "GroupCompanyId")[0];
                        //this.groups = json.result;
                        /* this.createGroupHierarchy(firstGroup, json.result).then(data => {
                            resolve(true);
                        }) */
                        resolve(json.result);
                    } else {
                        resolve(false);
                    }
                }

                // TODO: Handle errors on credential expiry / db move (re-authenticate)
            }

            //console.log("geoAuth: ", this.geoAuth);
            if (this.geoAuth == undefined) {
                console.log("geoAuth undefined, return");
                return;
            }
            request.open("POST", this.geoAuth.path == 'ThisServer' ? 'https://my.geotab.com/apiv1' : 'https://' + this.geoAuth.path + '/apiv1', true);
            request.setRequestHeader("Content-Type", "application/json");

            //console.log("groupId: ", groupId);

            var data = JSON.stringify({

                "method": "Get",
                "params": {
                    "typeName": "Group",
                    "credentials": this.geoAuth.credentials,
                    "search": {
                        "id": groupId
                    }
                }
            });

            request.send(data);
        });
    }

    async saveGroup(groupName, parent, comments?, color?, children?, groupId?): Promise<boolean> {
        if (parent && parent.id == "b278A") {
            return new Promise<boolean>(resolve => { resolve(false); });
        }
        if (groupId && groupId == "b278A") {
            return new Promise<boolean>(resolve => { resolve(false); });
        }
        const isAuth = this.geoAuth != undefined || await this.authenticateGeoTab();

        if (isAuth) {

            var data;

            if (!groupId) {

                let test = {
                    name: groupName,
                    parent: { id: parent.id, label: parent.label },
                    comments: comments,
                    color: color
                }
                console.log("lib saveGroup1: ", test);
                data = JSON.stringify({
                    "method": "Add",
                    "params": {
                        "typeName": "Group",
                        "credentials": this.geoAuth.credentials,
                        "entity": {
                            name: groupName,
                            parent: { id: parent.id, label: parent.label },
                            comments: comments,
                            color: color
                        }
                    }
                });
            } else {
                let saveGroup = {
                    name: groupName,
                    id: groupId,
                    parent: parent ? { id: parent.id, label: parent.label } : null,
                    comments: comments,
                    color: color,
                    children: children
                }
                console.log("lib saveGroup2: ", saveGroup);
                data = JSON.stringify({
                    "method": "Set",
                    "params": {
                        "typeName": "Group",
                        "credentials": this.geoAuth.credentials,
                        "entity": saveGroup
                    }
                });
            }

            var request = new XMLHttpRequest();

            return new Promise<boolean>(resolve => {
                request.onreadystatechange = (resp) => {
                    if ((<XMLHttpRequest>resp.currentTarget).readyState === 4 && (<XMLHttpRequest>resp.currentTarget).status === 200) {
                        var json = JSON.parse((<XMLHttpRequest>resp.currentTarget).responseText);
                        if (json && json.result != undefined) {
                            //zoneRef.value = json.result
                            resolve(true);
                        } else if (!groupId) {
                            console.log("error, group not saved: ", json);
                            resolve(false);
                        } else if (groupId) {
                            resolve(true);
                        }
                    }
                    // TODO: Handle errors on credential expiry / db move (re-authenticate)
                }

                request.open("POST", this.geoAuth.path == 'ThisServer' ? 'https://my.geotab.com/apiv1' : 'https://' + this.geoAuth.path + '/apiv1', true);
                request.setRequestHeader("Content-Type", "application/json");

                request.send(data);
            });
        } else {
            console.log("ERROR: GeoTab authentication failure");
        }
    }

    async deleteGroup(groupId, parentId): Promise<boolean> {
        if (groupId && groupId == "b278A") {
            return new Promise<boolean>(resolve => { resolve(false); });
        }
        if (!groupId) {
            return new Promise<boolean>(resolve => { resolve(false); });
        }

        const isAuth = this.geoAuth != undefined || await this.authenticateGeoTab();

        if (isAuth) {
            var data = JSON.stringify({
                "method": "Remove",
                "params": {
                    "typeName": "Group",
                    "credentials": this.geoAuth.credentials,
                    "entity": {
                        "id": groupId,
                        "parent": {
                            "id": parentId
                        }
                    }
                }
            });

            var request = new XMLHttpRequest();

            return new Promise<boolean>(resolve => {
                request.onreadystatechange = (resp) => {
                    //console.log("resp: ", resp);
                    if ((<XMLHttpRequest>resp.currentTarget).readyState === 4 && (<XMLHttpRequest>resp.currentTarget).status === 200) {
                        var json = JSON.parse((<XMLHttpRequest>resp.currentTarget).responseText);
                        resolve(true);
                    }
                    // TODO: Handle errors on credential expiry / db move (re-authenticate)
                }

                request.open("POST", this.geoAuth.path == 'ThisServer' ? 'https://my.geotab.com/apiv1' : 'https://' + this.geoAuth.path + '/apiv1', true);
                request.setRequestHeader("Content-Type", "application/json");

                request.send(data);
            });
        }
    }


    /* Rules:  */

    getAllRules(search?: any): Promise<any> {
        return new Promise((resolve, reject) => {
            var request = new XMLHttpRequest();

            request.onreadystatechange = (resp) => {
                if ((<XMLHttpRequest>resp.currentTarget).readyState === 4 && (<XMLHttpRequest>resp.currentTarget).status === 200) {
                    var json = JSON.parse((<XMLHttpRequest>resp.currentTarget).responseText);
                    if (json) {
                        //console.log("group: ", json.result)
                        //this.deviceList = json.result;
                        //resolve(true);
                        //const firstGroup = json.result.filter(x => x.id == "GroupCompanyId")[0];
                        //this.groups = json.result;
                        /* this.createGroupHierarchy(firstGroup, json.result).then(data => {
                            resolve(true);
                        }) */
                        //console.log("allRules: ", json.result);
                        resolve(json.result);
                    } else {
                        resolve(false);
                    }
                }

                // TODO: Handle errors on credential expiry / db move (re-authenticate)
            }

            request.open("POST", this.geoAuth.path == 'ThisServer' ? 'https://my.geotab.com/apiv1' : 'https://' + this.geoAuth.path + '/apiv1', true);
            request.setRequestHeader("Content-Type", "application/json");


            var data;

            if (search) {
                data = JSON.stringify({

                    "method": "Get",
                    "params": {
                        "typeName": "Rule",
                        "credentials": this.geoAuth.credentials,
                        "search": search
                    }
                });
            } else {
                data = JSON.stringify({

                    "method": "Get",
                    "params": {
                        "typeName": "Rule",
                        "credentials": this.geoAuth.credentials,
                    }
                });
            }

            request.send(data);
        });
    }

    getRuleById(ruleId): Promise<any> {
        return new Promise((resolve, reject) => {
            var request = new XMLHttpRequest();

            request.onreadystatechange = (resp) => {
                if ((<XMLHttpRequest>resp.currentTarget).readyState === 4 && (<XMLHttpRequest>resp.currentTarget).status === 200) {
                    var json = JSON.parse((<XMLHttpRequest>resp.currentTarget).responseText);
                    if (json) {
                        //console.log("group: ", json.result)
                        //this.deviceList = json.result;
                        //resolve(true);
                        //const firstGroup = json.result.filter(x => x.id == "GroupCompanyId")[0];
                        //this.groups = json.result;
                        /* this.createGroupHierarchy(firstGroup, json.result).then(data => {
                            resolve(true);
                        }) */
                        resolve(json.result);
                    } else {
                        resolve(false);
                    }
                }

                // TODO: Handle errors on credential expiry / db move (re-authenticate)
            }

            request.open("POST", this.geoAuth.path == 'ThisServer' ? 'https://my.geotab.com/apiv1' : 'https://' + this.geoAuth.path + '/apiv1', true);
            request.setRequestHeader("Content-Type", "application/json");

            var data = JSON.stringify({

                "method": "Get",
                "params": {
                    "typeName": "Rule",
                    "credentials": this.geoAuth.credentials,
                    "search": {
                        "id": ruleId
                    }
                }
            });

            request.send(data);
        });
    }


    /* Zones: */

    async saveZone(coordinates, zoneName, zoneRef, zoneTypes?, existingZone?): Promise<boolean> {
        const isAuth = this.geoAuth != undefined || await this.authenticateGeoTab();

        if (isAuth) {

            // console.log("saveZone geotab: ", zoneName, zoneTypes);

            // Fix coordinates format:
            var coords = [];
            coordinates.forEach(coord => {
                coords.push({ x: coord[0], y: coord[1] });
            });

            var data;

            if (!existingZone) {
                data = JSON.stringify({
                    "method": "Add",
                    "params": {
                        "typeName": "Zone",
                        "credentials": this.geoAuth.credentials,
                        "entity": {
                            name: zoneName,
                            zoneTypes: [{ id: zoneTypes }],
                            points: coords,
                            groups: [{
                                id: "GroupCompanyId"
                            }]
                        }
                    }
                });
            } else {
                this.zone.points = coords;
                data = JSON.stringify({
                    "method": "Set",
                    "params": {
                        "typeName": "Zone",
                        "credentials": this.geoAuth.credentials,
                        "entity": this.zone
                    }
                });
            }

            var request = new XMLHttpRequest();

            return new Promise<boolean>(resolve => {
                request.onreadystatechange = (resp) => {
                    if ((<XMLHttpRequest>resp.currentTarget).readyState === 4 && (<XMLHttpRequest>resp.currentTarget).status === 200) {
                        var json = JSON.parse((<XMLHttpRequest>resp.currentTarget).responseText);
                        if (json && json.result != undefined) {
                            zoneRef.value = json.result
                            resolve(true);
                        } else if (!existingZone) {
                            console.log("error, zone not saved: ", json);
                            resolve(false);
                        } else if (existingZone) {
                            resolve(true);
                        }
                    }
                    // TODO: Handle errors on credential expiry / db move (re-authenticate)
                }

                request.open("POST", this.geoAuth.path == 'ThisServer' ? 'https://my.geotab.com/apiv1' : 'https://' + this.geoAuth.path + '/apiv1', true);
                request.setRequestHeader("Content-Type", "application/json");

                request.send(data);
            });
        } else {
            console.log("ERROR: GeoTab authentication failure");
        }
    }

    async loadZone(zoneId): Promise<boolean> {

        const isAuth = this.geoAuth != undefined || await this.authenticateGeoTab();

        if (isAuth) {
            var data = JSON.stringify({
                "method": "Get",
                "params": {
                    "typeName": "Zone",
                    "credentials": this.geoAuth.credentials,
                    "search": {
                        "id": zoneId
                    }
                }
            });

            var request = new XMLHttpRequest();

            return new Promise<boolean>(resolve => {
                request.onreadystatechange = (resp) => {
                    if ((<XMLHttpRequest>resp.currentTarget).readyState === 4 && (<XMLHttpRequest>resp.currentTarget).status === 200) {
                        var json = JSON.parse((<XMLHttpRequest>resp.currentTarget).responseText);
                        if (json && json.result.length > 0) {
                            this.zone = json.result[0];
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    }
                    // TODO: Handle errors on credential expiry / db move (re-authenticate)
                }

                request.open("POST", this.geoAuth.path == 'ThisServer' ? 'https://my.geotab.com/apiv1' : 'https://' + this.geoAuth.path + '/apiv1', true);
                request.setRequestHeader("Content-Type", "application/json");

                request.send(data);
            });
        }
    }

    loadZoneListData(): Promise<boolean> {
        var data = JSON.stringify({
            "method": "Get",
            "params": {
                "typeName": "ZoneType",
                "credentials": this.geoAuth.credentials,
            }
        });

        var request = new XMLHttpRequest();

        return new Promise<boolean>(resolve => {
            request.onreadystatechange = (resp) => {
                if ((<XMLHttpRequest>resp.currentTarget).readyState === 4 && (<XMLHttpRequest>resp.currentTarget).status === 200) {
                    var json = JSON.parse((<XMLHttpRequest>resp.currentTarget).responseText);
                    this.zoneList = [];
                    if (json && json.result.length > 0) {
                        json.result.forEach((r, index) => {
                            if (r['name'] != undefined) {
                                this.zoneList.push(r);
                            }
                            if (index == json.result.length - 1) {
                                resolve(true);
                            }
                        });
                    } else {
                        resolve(false);
                    }
                }
                // TODO: Handle errors on credential expiry / db move (re-authenticate)
            }


            request.open("POST", this.geoAuth.path == 'ThisServer' ? 'https://my.geotab.com/apiv1' : 'https://' + this.geoAuth.path + '/apiv1', true);
            request.setRequestHeader("Content-Type", "application/json");

            request.send(data);
        });
    }

    async getZoneInViewport(viewport): Promise<boolean> {

        const isAuth = this.geoAuth != undefined || await this.authenticateGeoTab();

        if (isAuth) {
            var data = JSON.stringify({
                "method": "Get",
                "params": {
                    "typeName": "Zone",
                    "credentials": this.geoAuth.credentials,
                    "search": {
                        "viewport": viewport
                    }
                }
            });

            var request = new XMLHttpRequest();

            return new Promise<boolean>(resolve => {
                request.onreadystatechange = (resp) => {
                    if ((<XMLHttpRequest>resp.currentTarget).readyState === 4 && (<XMLHttpRequest>resp.currentTarget).status === 200) {
                        var json = JSON.parse((<XMLHttpRequest>resp.currentTarget).responseText);
                        if (json && json.result && json.result.length > 0) {
                            this.allZones = json.result;
                            // out current zone:
                            if (this.allZones.length && this.zone != undefined && this.zone != null) {
                                this.allZones = this.allZones.filter(x => x.id != this.zone.id);
                            }
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    }
                    // TODO: Handle errors on credential expiry / db move (re-authenticate)
                }


                request.open("POST", this.geoAuth.path == 'ThisServer' ? 'https://my.geotab.com/apiv1' : 'https://' + this.geoAuth.path + '/apiv1', true);
                request.setRequestHeader("Content-Type", "application/json");

                request.send(data);
            });
        }
    }

    async deleteZone(zoneId): Promise<boolean> {

        const isAuth = this.geoAuth != undefined || await this.authenticateGeoTab();

        if (isAuth) {
            var data = JSON.stringify({
                "method": "Remove",
                "params": {
                    "typeName": "Zone",
                    "credentials": this.geoAuth.credentials,
                    "entity": {
                        "id": zoneId
                    }
                }
            });

            var request = new XMLHttpRequest();

            return new Promise<boolean>(resolve => {
                request.onreadystatechange = (resp) => {
                    if ((<XMLHttpRequest>resp.currentTarget).readyState === 4 && (<XMLHttpRequest>resp.currentTarget).status === 200) {
                        var json = JSON.parse((<XMLHttpRequest>resp.currentTarget).responseText);
                        this.zone = null;
                        resolve(true);
                    }
                    // TODO: Handle errors on credential expiry / db move (re-authenticate)
                }

                request.open("POST", this.geoAuth.path == 'ThisServer' ? 'https://my.geotab.com/apiv1' : 'https://' + this.geoAuth.path + '/apiv1', true);
                request.setRequestHeader("Content-Type", "application/json");

                request.send(data);
            });
        }
    }


    /* Helper Functions */

    getAddress(points): Promise<boolean> {
        var data = JSON.stringify({
            "method": "GetAddresses",
            "params": {
                "coordinates": points,
                "credentials": this.geoAuth.credentials,
            }
        });

        var request = new XMLHttpRequest();

        return new Promise((resolve, reject) => {
            request.onreadystatechange = (resp) => {
                if ((<XMLHttpRequest>resp.currentTarget).readyState === 4 && (<XMLHttpRequest>resp.currentTarget).status === 200) {
                    var json = JSON.parse((<XMLHttpRequest>resp.currentTarget).responseText);
                    this.tripData.forEach((trip, index) => {
                        if (index == 0) {
                            trip.prevLocation = json.result[index].formattedAddress;
                        }
                        trip.location = json.result[index + 1].formattedAddress;
                        if (index + 1 == this.tripData.length) {
                            resolve(true);
                            return;
                        }
                    });
                }
                // TODO: Handle errors on credential expiry / db move (re-authenticate)
            }

            request.open("POST", this.geoAuth.path == 'ThisServer' ? 'https://my.geotab.com/apiv1' : 'https://' + this.geoAuth.path + '/apiv1', true);
            request.setRequestHeader("Content-Type", "application/json");

            request.send(data);
        });
    }

    testing(deviceId, geoTypeName, geoSearch): Promise<boolean> {
        return new Promise((resolve, reject) => {
            var request = new XMLHttpRequest();

            request.onreadystatechange = (resp) => {
                if ((<XMLHttpRequest>resp.currentTarget).readyState === 4 && (<XMLHttpRequest>resp.currentTarget).status === 200) {
                    var json = JSON.parse((<XMLHttpRequest>resp.currentTarget).responseText);
                    //console.log("json: ", json);
                    if (json && json.result.length) {
                        //this.deviceLogRecord = json.result[0];
                        //this.deviceStatusInfo = json.result[0];

                        resolve(true);
                        //this.showMap(json.result[0].latitude, json.result[0].longitude);
                    } else {
                        console.log("error, no result for testing, device: ", deviceId, " api resp: ", resp);
                        resolve(false);
                    }
                }

                // TODO: Handle errors on credential expiry / db move (re-authenticate)
            }

            request.open("POST", this.geoAuth.path == 'ThisServer' ? 'https://my.geotab.com/apiv1' : 'https://' + this.geoAuth.path + '/apiv1', true);
            request.setRequestHeader("Content-Type", "application/json");

            var fromDate = new Date();
            var toDate = new Date(fromDate);
            fromDate.setMinutes(toDate.getMinutes() - 10);


            // ExceptionEvent


            // rulePostedSpeedingId
            // ruleHarshBrakingId
            // ruleHarshCorneringId
            // ruleSeatbeltId

            var data = JSON.stringify({
                "method": "Get",
                "params": {
                    "typeName": geoTypeName,
                    "credentials": this.geoAuth.credentials,
                    "search": geoSearch
                }
            });

            request.send(data);
        });
    }

    getSecondsFromTime(time) {
        let dd;
        let hh;
        let mm;
        let ss;
        // [-]
        if (time.charAt(0) == '-') {
            time = time.substr(1);
        }
        // [dd.]
        if (time.indexOf('.') != -1 && time.indexOf('.') < time.indexOf(':')) {
            dd = time.substring(0, time.indexOf('.'));
            time = time.substring(time.indexOf('.') + 1);
        }
        // remove [.hhhh]
        if (time.indexOf('.') != -1) {
            time = time.substring(0, time.indexOf('.'));
        }
        const timeArray = time.split(":");
        if (timeArray.length != 3) {
            console.log("time error: ", time);
        }

        hh = timeArray[0].replace(/^0+/, '');
        mm = timeArray[1].replace(/^0+/, '');
        ss = timeArray[2].replace(/^0+/, '');
        if (!dd) { dd = 0; }
        if (!hh) { hh = 0; }
        if (!mm) { mm = 0; }
        if (!ss) { ss = 0; }

        return (ss * 1) + (mm * 60) + (hh * 60 * 60) + (dd * 24 * 60 * 60);
    }

    addTimes(time1, time2) {
        var t1s = 0;
        var t2s = 0;
        if (time1 == null && time2 == null) {
            return null;
        } else if (time1 == null) {
            t2s = this.getSecondsFromTime(time2) * 1;
        } else if (time2 == null) {
            t1s = this.getSecondsFromTime(time1) * 1;
        } else {
            t1s = this.getSecondsFromTime(time1) * 1;
            t2s = this.getSecondsFromTime(time2) * 1;
        }
        var d = new Date(0);
        d.setSeconds(t1s + t2s);
        var timeString = d.toISOString().substr(11, 8);
        return timeString;
    }

    formatTimeSpan(time: string): string {
        if (!time) { return time }
        if (time == '00:00:00') { return '' }

        let dd;
        let hh;
        let mm;
        let ss;
        // [-]
        if (time.charAt(0) == '-') {
            time = time.substr(1);
        }
        // [dd.]
        if (time.indexOf('.') != -1 && time.indexOf('.') < time.indexOf(':')) {
            dd = time.substring(0, time.indexOf('.'));
            time = time.substring(time.indexOf('.') + 1);
        }
        // remove [.hhhh]
        if (time.indexOf('.') != -1) {
            time = time.substring(0, time.indexOf('.'));
        }
        const timeArray = time.split(":");
        if (timeArray.length != 3) {
            return time;
        }

        hh = timeArray[0].replace(/^0+/, '');
        mm = timeArray[1].replace(/^0+/, '');
        ss = timeArray[2].replace(/^0+/, '');

        let finalTime = '';
        if (dd) {
            finalTime = dd + ' d ';
        }
        if (hh && hh != '') {
            finalTime = finalTime + hh + ' h ';
        }
        if (mm && mm != '') {
            finalTime = finalTime + mm + ' m ';
        }
        /* if (ss && ss != '') {
            finalTime = finalTime + ss + ' s ';
        } */

        return finalTime.replace(/\s*$/, '');
    }

    formatDateTime(date, hideDate?: boolean) {
        var year = date.getFullYear(),
            month = date.getMonth() + 1, // months are zero indexed
            day = date.getDate(),
            hour = date.getHours(),
            minute = date.getMinutes(),
            second = date.getSeconds(),
            hourFormatted = hour % 12 || 12, // hour returned in 24 hour format
            minuteFormatted = minute < 10 ? "0" + minute : minute,
            secondFormatted = second < 10 ? "0" + second : second,
            morning = hour < 12 ? " am" : " pm";

        if (hideDate) {
            return hourFormatted + ":" + minuteFormatted + ':' + secondFormatted + morning;
        } else {
            return month + "/" + day + "/" + year + " " + hourFormatted + ":" + minuteFormatted + ':' + secondFormatted + morning;
        }
    }


    /* setDeviceData(deviceId): Promise<boolean> {

        var data = JSON.stringify({
            "method": "Get",
            "params": {
                "typeName": "Device",
                "credentials": this.geoAuth.credentials,
                "search": {
                    "id": deviceId
                }
            }
        });

        var request = new XMLHttpRequest();

        return new Promise((resolve, reject) => {
            request.onreadystatechange = (resp) => {
                if ((<XMLHttpRequest>resp.currentTarget).readyState === 4 && (<XMLHttpRequest>resp.currentTarget).status === 200) {
                    var json = JSON.parse((<XMLHttpRequest>resp.currentTarget).responseText);
                    if (json && json.result.length) {
                        this.device = json.result[0];
                        resolve(true);
                    } else {
                        console.log("error, no result for get device: ", deviceId, " api resp: ", resp);
                        resolve(false);
                    }
                }
                // TODO: Handle errors on credential expiry / db move (re-authenticate)
            }

            request.open("POST", this.geoAuth.path == 'ThisServer' ? 'https://my.geotab.com/apiv1' : 'https://' + this.geoAuth.path + '/apiv1', true);
            request.setRequestHeader("Content-Type", "application/json");

            request.send(data);
        });
    } */

    /* setOdometerData(deviceId): Promise<boolean> {
        var data = JSON.stringify({
            "method": "Get",
            "params": {
                "typeName": "StatusData",
                "credentials": this.geoAuth.credentials,
                "search": {
                    "deviceSearch": {
                        "id": deviceId
                    },
                    "diagnosticSearch": {
                        "id": "DiagnosticOdometerAdjustmentId"
                    },
                    'fromDate': new Date(),
                    'toDate': new Date(),
                }
            }
        });


        var request = new XMLHttpRequest();

        return new Promise((resolve, reject) => {
            request.onreadystatechange = (resp) => {
                if ((<XMLHttpRequest>resp.currentTarget).readyState === 4 && (<XMLHttpRequest>resp.currentTarget).status === 200) {
                    var json = JSON.parse((<XMLHttpRequest>resp.currentTarget).responseText);
                    if (json && json.result.length) {
                        this.odometer = (json.result[0].data / 1000).toFixed(0);
                        resolve(true);
                    } else {
                        console.log("error, no odometer result for device: ", deviceId, " api resp: ", resp);
                        resolve(false);
                    }
                }
                // TODO: Handle errors on credential expiry / db move (re-authenticate)
            }

            request.open("POST", this.geoAuth.path == 'ThisServer' ? 'https://my.geotab.com/apiv1' : 'https://' + this.geoAuth.path + '/apiv1', true);
            request.setRequestHeader("Content-Type", "application/json");

            request.send(data);
        });
    } */

    /* setEngineHourData(deviceId): Promise<boolean> {
        var data = JSON.stringify({
            "method": "Get",
            "params": {
                "typeName": "StatusData",
                "credentials": this.geoAuth.credentials,
                "search": {
                    "deviceSearch": {
                        "id": deviceId
                    },
                    "diagnosticSearch": {
                        "id": "DiagnosticEngineHoursAdjustmentId"
                    },
                    'fromDate': new Date(),
                    'toDate': new Date(),
                }
            }
        });


        var request = new XMLHttpRequest();

        return new Promise((resolve, reject) => {
            request.onreadystatechange = (resp) => {
                if ((<XMLHttpRequest>resp.currentTarget).readyState === 4 && (<XMLHttpRequest>resp.currentTarget).status === 200) {
                    var json = JSON.parse((<XMLHttpRequest>resp.currentTarget).responseText);
                    if (json && json.result.length) {
                        this.engineHours = (json.result[0].data / 3600).toFixed(2);
                        resolve(true);
                    } else {
                        console.log("error, no engine hours result for device: ", deviceId, " api resp: ", resp);
                        resolve(false);
                    }
                }
                // TODO: Handle errors on credential expiry / db move (re-authenticate)
            }

            request.open("POST", this.geoAuth.path == 'ThisServer' ? 'https://my.geotab.com/apiv1' : 'https://' + this.geoAuth.path + '/apiv1', true);
            request.setRequestHeader("Content-Type", "application/json");

            request.send(data);
        });
    } */


    /* async getLocation(deviceId) {
        const isAuth = this.geoAuth != undefined || await this.authenticateGeoTab();

        if (isAuth) {
            var request = new XMLHttpRequest();

            request.onreadystatechange = (resp) => {
                if ((<XMLHttpRequest>resp.currentTarget).readyState === 4 && (<XMLHttpRequest>resp.currentTarget).status === 200) {
                    var json = JSON.parse((<XMLHttpRequest>resp.currentTarget).responseText);
                    if (json && json.result.length) {
                        this.showMap(json.result[0].latitude, json.result[0].longitude);
                    } else {
                        console.log("error, no result for getLocation, device: ", deviceId, " api resp: ", resp);
                    }
                }

                // TODO: Handle errors on credential expiry / db move (re-authenticate)
            }

            request.open("POST", this.geoAuth.path == 'ThisServer' ? 'https://my.geotab.com/apiv1' : 'https://' + this.geoAuth.path + '/apiv1', true);
            request.setRequestHeader("Content-Type", "application/json");

            var data = JSON.stringify({
                "method": "Get",
                "params": {
                    "typeName": "DeviceStatusInfo",
                    "credentials": this.geoAuth.credentials,
                    "search": {
                        "deviceSearch": {
                            "id": deviceId
                        }
                    }
                }
            });

            request.send(data);
        }
    } */

    /*  showMap(lat, long) {
         if (lat == undefined || long == undefined) {
             console.log("Error, latitude longitude undefined");
         } else {
             window.open('https://www.google.com/maps/search/?api=1&query=' + lat + ',' + long, "_blank");
         }
     } */


    /* refreshDevice(deviceId): Promise<boolean> {
        return new Promise((resolve, reject) => {
            var request = new XMLHttpRequest();

            request.onreadystatechange = (resp) => {
                if ((<XMLHttpRequest>resp.currentTarget).readyState === 4 && (<XMLHttpRequest>resp.currentTarget).status === 200) {
                    var json = JSON.parse((<XMLHttpRequest>resp.currentTarget).responseText);
                    if (json && json.result.length) {
                        let updatedDevice = json.result[0];
                        let currDevice = this.deviceList.filter(x => x.id == updatedDevice.id)[0];
                        console.log("currDevice: ", currDevice);
                        for (let key in currDevice) {
                            currDevice[key] = updatedDevice[key];
                        }
                        console.log("updated: ", updatedDevice);
                        resolve(true);
                        //this.showMap(json.result[0].latitude, json.result[0].longitude);
                    } else {
                        console.log("error, no result for getLocation, device: ", deviceId, " api resp: ", resp);
                        resolve(false);
                    }
                }

                // TODO: Handle errors on credential expiry / db move (re-authenticate)
            }

            request.open("POST", this.geoAuth.path == 'ThisServer' ? 'https://my.geotab.com/apiv1' : 'https://' + this.geoAuth.path + '/apiv1', true);
            request.setRequestHeader("Content-Type", "application/json");

            var data = JSON.stringify({
                "method": "Get",
                "params": {
                    "typeName": "Device",
                    "credentials": this.geoAuth.credentials,
                    "search": {
                        "id": deviceId
                    }
                }
            });

            request.send(data);
        });
    } */

    /* async getGeoTabCount() {
        const isAuth = this.geoAuth != undefined || await this.authenticateGeoTab();

        if (isAuth) {
            var request = new XMLHttpRequest();

            request.onreadystatechange = (resp) => {
                if ((<XMLHttpRequest>resp.currentTarget).readyState === 4 && (<XMLHttpRequest>resp.currentTarget).status === 200) {
                    var json = JSON.parse((<XMLHttpRequest>resp.currentTarget).responseText);
                }

                // TODO: Handle errors on credential expiry / db move (re-authenticate)
            }

            request.open("POST", this.geoAuth.path == 'ThisServer' ? 'https://my.geotab.com/apiv1' : 'https://' + this.geoAuth.path + '/apiv1', true);
            request.setRequestHeader("Content-Type", "application/json");


            var data = JSON.stringify({

                "method": "GetCountOf",
                "params": {
                    "typeName": "Device",
                    "credentials": this.geoAuth.credentials
                }
            });

            request.send(data);
        }
    } */

}


