import {Component, AfterViewInit, OnInit, ViewChild, ElementRef, HostListener} from '@angular/core';
import {UpDialogComponent, UpDialogRef} from '@ellira/upstrap-dialog';

@Component({
    templateUrl: './edit-field-dialog.component.html'
})
export class EditFieldDialogComponent extends UpDialogComponent<any> implements OnInit, AfterViewInit {

    data: any;
    isValid: boolean = false;

    chipsArray: Array<any>;
    @ViewChild('upChipGroup') chipGroup;
    @ViewChild('upInput', {static: true, read: ElementRef}) private upInput: ElementRef;
    @ViewChild('upInput') private upInputComp;
    @ViewChild('upDatepicker', {static: true, read: ElementRef}) private upDatepicker: ElementRef;
    @ViewChild('upDatepicker') private upDatepickerComp;
    @ViewChild('upNumberbox', {static: true, read: ElementRef}) private upNumberbox: ElementRef;
    @ViewChild('card', {static: true, read: ElementRef}) private card: ElementRef;
    @ViewChild('upNumberbox') private upNumberboxComp;

    @HostListener('document:keydown', ['$event'])
    onKeydown(e) {
        if (e.key == "Escape" || e.code == "Escape") {
            this.onClose(this.data);
        }
    }

    constructor(public dialogRef: UpDialogRef<EditFieldDialogComponent>) {
        super();
    }

    ngOnInit() {
        this.chipsArray = this.data.dialogData;
    }

    ngAfterViewInit() {
        if (this.data.dialogType === 'input') {
            Promise.resolve(null).then(() => {
                // Set focus to input:
                this.upInput.nativeElement.querySelector('input').focus();
                // Check validity:
                this.upInputComp.checkValidation();
                this.isValid = this.upInputComp.isValid();
            });
        } else if (this.data.dialogType === 'datepicker') {
            Promise.resolve(null).then(() => {
                // Set focus to datepicker:
                this.upDatepicker.nativeElement.querySelector('input').focus();
                // Check validity:
                this.isValid = this.upDatepickerComp.isValid();
            });
        } else if (this.data.dialogType === 'numberbox') {
            if (this.data.numberDecimal == undefined && this.data.isCurrency != true) {
                this.data.numberDecimal = 0;
            } else if (this.data.numberDecimal == undefined && this.data.isCurrency == true) {
                this.data.numberDecimal = 2;
            }
            // Set focus to numberbox:
            Promise.resolve(null).then(() => {
                this.upNumberbox.nativeElement.querySelector('input').focus();
                // Check validity:
                this.isValid = this.upNumberboxComp.isValid();

            });
        } else if (this.data.dialogType === 'chip') {
            this.chipsArray.forEach(chip => {
                Promise.resolve(null).then(() => this.chipGroup.set(chip));
            });
        }
    }

    validate() {
        if (this.data.dialogType === 'input') {
            this.upInputComp.checkValidation();
            this.isValid = this.upInputComp.isValid();
        } else if (this.data.dialogType === 'datepicker') {
            // this.upDatepickerComp.checkValidation();
            this.isValid = this.upDatepickerComp.isValid();
        }
    }

    onClose(data) {
        this.dialogRef.close(data, 'cancel');
    }

    onSuccess(data) {
        if (!this.isValid) {
            return;
        }
        console.log('data: ', data);
        this.dialogRef.close(data, 'save');
    }
}
