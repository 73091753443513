<up-dialog #dialog>
    <up-dialog-header>
        {{ title }}
    </up-dialog-header>
    <up-dialog-body>
        <div [ngStyle]="{'padding-bottom': data.addOnText ? '1rem' : 'inherit'}">{{ message }}</div>
        <div *ngIf="data?.addOnText" [ngStyle]="{'padding-bottom': data.addOnText2 ? '1rem' : 'inherit'}">{{ data.addOnText }}</div>
        <div *ngIf="data?.addOnText2">{{ data.addOnText2 }}</div>
        <div *ngIf="data?.replaceData?.dataSource" style="max-width: 400px;padding-top: 1rem;">
            <up-select [(ngModel)]="replaceValue" [dataSource]="data?.replaceData.dataSource" [valueField]="data?.replaceData.valueField" [textField]="data?.replaceData.textField" label="Replace With" filterable></up-select>
        </div>
    </up-dialog-body>
    <up-dialog-footer>
        <up-button (click)="onClose(data)">{{ cancelText }}</up-button>
        <up-button #submitBtn type="submit" color="danger" (click)="onSuccess(data)" filled [disabled]="data?.replaceData?.dataSource && replaceValue == null">{{ confirmText }}</up-button>
    </up-dialog-footer>
</up-dialog>
