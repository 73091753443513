import { Component, HostListener, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EquipmentService } from 'src/app/equipment/equipment.service';
import { VehicleService } from 'src/app/vehicle/vehicle.service';
import { UpDialogComponent, UpDialogRef } from '@ellira/upstrap-dialog';
import { VehicleModel } from 'src/app/vehicle/vehicle-models';
import { EquipmentModel } from 'src/app/equipment/equipment-models';
import { GeoTabService } from '../../geotab/geotab.service';

export interface AttachGPSDeviceData {
}

export class GPSDeviceDisplayData {
    serialNumber: string;
    provider: string;
    deviceType: string;
	version: string;
	ratePlan: string;
}

@Component({
    templateUrl: './attach-gps-device-dialog.component.html'
})

export class AttachGPSDeviceDialogComponent extends UpDialogComponent<AttachGPSDeviceData> implements OnInit {
    data: any;
    formReady = false;
    detailForm: FormGroup;
    loadingDevices = false;
    checkingAssigned = false;
    alreadyAssignedVehicle: VehicleModel;
    alreadyAssignedEquipment: EquipmentModel;
    devices: any;

    @HostListener('document:keydown', ['$event'])
    onKeydown(e) {
        if (e.key == "Escape" || e.code == "Escape") {
            this.onClose();
        }
    }

    constructor(public dialogRef: UpDialogRef<AttachGPSDeviceDialogComponent>,
        private fb: FormBuilder,
        private vehicleService: VehicleService,
        private equipmentService: EquipmentService,
        private geotabService: GeoTabService,
        public sharedService: SharedService) {
        super();
    }

    ngOnInit() {
        this.formReady = false;
        this.init();
    }

    async init() {
        this.detailForm = this.fb.group({
            gPSProviderId:  ['', [Validators.required]],
            gPSDeviceTypeId:  ['', [Validators.required]],
            gPSDeviceId: ['', [Validators.required]]
        });
        this.detailForm.get('gPSProviderId').valueChanges.subscribe(val => {
            this.loadDevices(val);
        });
        this.detailForm.get('gPSDeviceId').valueChanges.subscribe(val => {
            if (val) {
                this.checkingAssigned = true;
                this.checkAssignedVehicle(val);
            }
        });
        setTimeout(() => {
            this.formReady = true;
        }, 500);
        
    }

    checkAssignedVehicle(id) {
        this.vehicleService.getList({gPSDeviceId: id}).subscribe(
            resp => {
                if (resp.body.results.length > 0) {
                    this.alreadyAssignedVehicle = resp.body.results[0];
                    this.checkingAssigned = false;
                } else {
                    this.alreadyAssignedVehicle = null;
                    this.checkAssignedEquipment(id);
                }
            },
            error => {
                this.alreadyAssignedVehicle = null;
                this.checkAssignedEquipment(id);
            }
        )
    }

    checkAssignedEquipment(id) {
        this.equipmentService.getList({gPSDeviceId: id}).subscribe(
            resp => {
                if (resp.body.results.length > 0) {
                    this.alreadyAssignedEquipment = resp.body.results[0];
                    this.checkingAssigned = false;
                } else {
                    this.alreadyAssignedEquipment = null;
                }
                this.checkingAssigned = false;
            },
            error => {
                this.checkingAssigned = false;
                this.alreadyAssignedEquipment = null;
            }
        )
    }

    async loadDevices(gPSProviderId) {
        this.loadingDevices = true;
        this.devices = [];
        if (gPSProviderId == 1) {
            // Geotab GPS:
            const hasAuth = await this.geotabService.authenticateGeoTab();
            console.log("hasAuth")
            const loadDevices = await this.geotabService.loadDeviceList();
            console.log("loadDevices")
            if (hasAuth && loadDevices) {
                this.devices = [];
                this.geotabService.deviceList.forEach(d => {
                    if (new Date(d.activeTo) < new Date()) {

                    } else {
                        this.devices.push(d);
                    }
                })
                this.devices.sort((x, y) => x.name.localeCompare(y.name));
                console.log("devices: ", this.devices);
                this.loadingDevices = false;
            }
        } else {
            // Skye GPS:
            this.devices = [];
            this.loadingDevices = false; 
        }
    }

    onClose() {
        this.dialogRef.close(this.data, 'cancel');
    }

    save() {
        this.data.results = this.detailForm.value;
        this.data.results.gPSSerialNumber = this.devices.filter(x => x.id == this.data.results.gPSDeviceId)[0].serialNumber;
        this.data.results.alreadyAssignedVehicle = this.alreadyAssignedVehicle;
        this.data.results.alreadyAssignedEquipment = this.alreadyAssignedEquipment;
        this.dialogRef.close(this.data, 'save');
    }

    addNewGeotabDevice() {
        
    }

}
