import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeoTabService, GeoTabTimeSpanPipe } from './geotab.service';
import { GeofenceComponent } from './geofence/geofence.component';
import { GeofenceDetailDialogComponent } from './geofence/detail/geofence-detail-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UpstrapCoreModule } from '@ellira/upstrap-core';
import { UpstrapCardModule } from '@ellira/upstrap-card';
import { UpstrapButtonModule } from '@ellira/upstrap-button';
import { UpstrapDialogModule } from '@ellira/upstrap-dialog';
import { UpstrapInputModule } from '@ellira/upstrap-input';
import { UpstrapSelectModule } from '@ellira/upstrap-select';
import { UpstrapCheckboxModule } from '@ellira/upstrap-checkbox';
import { UpstrapChipModule } from '@ellira/upstrap-chip';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UpstrapCoreModule,
        UpstrapCardModule,
        UpstrapButtonModule,
        UpstrapDialogModule,
        UpstrapInputModule,
        UpstrapSelectModule,
        UpstrapCheckboxModule,
        UpstrapChipModule,
        VirtualScrollerModule,
    ],
    declarations: [
        GeofenceComponent,
        GeofenceDetailDialogComponent,
        GeoTabTimeSpanPipe
    ],
    exports: [
        GeofenceComponent,
        GeoTabTimeSpanPipe
    ],
    providers: [
        GeoTabService,
        GeoTabTimeSpanPipe
    ],
    entryComponents: [
        GeofenceDetailDialogComponent
    ]
})
export class GeotabModule { }
