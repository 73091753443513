import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { UpstrapAlertModule } from '@ellira/upstrap-alert';
import { UpstrapAppBarModule } from '@ellira/upstrap-app-bar';
import { UpstrapButtonModule } from '@ellira/upstrap-button';
import { UpstrapCoreModule } from '@ellira/upstrap-core';
import { UpstrapDrawerModule } from '@ellira/upstrap-drawer';
import { UpstrapInputModule } from '@ellira/upstrap-input';
import { UpstrapMenuModule } from '@ellira/upstrap-menu';

import { AppRoutingModule } from './app-routing.module';
import { CacheModule } from './shared/cache/cache.module';
import { DialogsModule } from './shared/dialogs/dialogs.module';
import { SecurityModule } from './shared/security/security.module';
import { HttpInterceptorModule } from './shared/security/http-interceptor.module';

import { AppComponent } from './app.component';
import { ErrorPageModule } from './shared/error-page/error-page.module';
import { BrowserTransferStateModule } from '@angular/platform-browser';
import { GlobalErrorHandler } from './shared/error-handler';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserTransferStateModule,
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        ErrorPageModule,
        AppRoutingModule,
        RouterModule,
        HttpClientModule,
        HttpInterceptorModule,

        UpstrapCoreModule,
        UpstrapAppBarModule,
        UpstrapDrawerModule,
        UpstrapInputModule,
        UpstrapButtonModule,
        UpstrapMenuModule,
        UpstrapAlertModule,

        CacheModule,
        DialogsModule,
        SecurityModule,
    ],
    providers: [{provide: ErrorHandler, useClass: GlobalErrorHandler}],
    bootstrap: [AppComponent]
})
export class AppModule { }