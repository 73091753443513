<up-dialog>
    <up-dialog-header>Change Password</up-dialog-header>
    <up-dialog-body>
        <up-loader spinner *ngIf="!formReady"></up-loader>
        <div #alerts></div>
        <span *ngIf="formReady">
            <form #form (ngSubmit)="save()" [formGroup]="passwordForm" autocomplete="off" novalidate>
                <div class="row">
                    <div class="col">
                        <up-input formControlName="oldPassword" [formData]="passwordForm.get('oldPassword')" format="password" label="Old Password" autofocus="autofocus"></up-input>
                        <up-input formControlName="password" [formData]="passwordForm.get('password')" format="password" label="New Password"></up-input>
                        <up-input formControlName="confirmPassword" [formData]="passwordForm.get('confirmPassword')" format="password" label="Confirm Password"></up-input>
                    </div>
                </div>
            </form>
        </span>
    </up-dialog-body>
    <up-dialog-footer>
        <span *ngIf="formReady">
            <up-button (click)="onClose()">Cancel</up-button>
            <up-button filled [disabled]="!passwordForm?.valid" [spinLoad]="saving" spinText="Saving" (click)="save()" (keyup.enter)="save()">Save</up-button>
        </span>
    </up-dialog-footer>
</up-dialog>