<up-dialog>
    <up-dialog-body>
        <up-input #upInput *ngIf="data.dialogType == 'input'" [required]="data.dataRequired"
                  [errorMessage]="data.dataRequired ? ['This field is required'] : null" type="underline"
                  [(ngModel)]="data.dialogData" (ngModelChange)="validate()" [placeholder]="data.dialogPlaceholder"
                  (keydown.enter)="onSuccess(data)" showClearButton="true"></up-input>
        <up-datepicker #upDatepicker *ngIf="data.dialogType == 'datepicker'" [required]="data.dataRequired"
                       [errorMessage]="data.dataRequired ? ['This field is required'] : null" validateDate="true"
                       type="underline" [(ngModel)]="data.dialogData" (ngModelChange)="validate()"
                       (keydown.enter)="onSuccess(data)" (keydown.esc)="onClose(data)"
                       showClearButton="true"></up-datepicker>
        <up-number-box #upNumberbox *ngIf="data.dialogType == 'numberbox'" [currency]="data.isCurrency"
                       [decimal]="data.numberDecimal" [required]="data.dataRequired"
                       [errorMessage]="data.dataRequired ? ['This field is required'] : null" type="underline"
                       [currency]="data.isCurrency" [(ngModel)]="data.dialogData" (ngModelChange)="validate()"
                       [placeholder]="data.dialogPlaceholder" (keydown.enter)="onSuccess(data)"
                       showClearButton="true"></up-number-box>
        <!--<div *ngIf="data.dialogType == 'chip'">
            <up-chip-group #upChipGroup multiple [(ngModel)]="data.dialogData">
                <up-chip *ngFor="let type of data.dialogAvailableChips;" size="small" [value]="type">{{ type }}</up-chip>
            </up-chip-group>
        </div>-->
    </up-dialog-body>
    <up-dialog-footer>
        <up-button (click)="onClose(data)">Cancel</up-button>
        <up-button [disabled]="!isValid" (click)="onSuccess(data)" filled>Save</up-button>
    </up-dialog-footer>
</up-dialog>
