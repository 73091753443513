<up-card [hidden]="!geofenceReady" [fullwidth]="noCard">
    <up-card-header>Geofence Zone</up-card-header>
    <up-card-body>
        <p *ngIf="!geofenceComplete">
            Click on the map to create geofounce zone boundaries
        </p>
        <up-button *ngIf="geofenceComplete" (click)="clearGeofence()" filled style="margin-bottom: 4px;">Clear Geofence
        </up-button>
        <div [id]="identifier" style="width: 100%; height: 500px;"></div>
    </up-card-body>
</up-card>