import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ICachedObject, ListCacheUpdate, NewCacheService } from 'src/app/shared/cache/new-cache.service';
import { SharedService } from 'src/app/shared/shared.service';
import { EquipmentList, EquipmentModel } from './equipment-models';

@Injectable({
	providedIn: 'root'
})
export class EquipmentService {
	url = this.sharedService.equipmentsApiUrl + 'equipment';
	cache: ICachedObject;

	constructor(private http: HttpClient,
		private cacheService: NewCacheService,
		public sharedService: SharedService) {
		this.cache = new ICachedObject;
		this.cache.cacheKey = 'listcache-equipment';
		this.cache.listId = 'equipmentId';
		this.cache.getFn = this.getList.bind(this);
	}

	getList(params?: any): Observable<HttpResponse<EquipmentList>> {
		return this.http.get<EquipmentList>(params != undefined && Object.keys(params).length != 0 ? this.url + '?' + this.sharedService.param(params) : this.url, { observe: 'response' });
	}

	getCachedList(): Promise<EquipmentModel[]> {
		return this.cacheService.checkCachedList(this.cache);
	}

	checkDuplicate(field: string, duplicate: string, idValue?: number): Observable<HttpResponse<any>> {
		const params = {
			fieldName: field,
			fieldValue: duplicate,
		}
		if (idValue) {
			params[this.cache.listId] = idValue
		}
		return this.http.get<any>(this.url + '/exists/exists' + '?' + this.sharedService.param(params), { observe: 'response'});
	}

	get(id: number): Observable<HttpResponse<EquipmentModel>> {
		return this.http.get<EquipmentModel>(this.url + '/' + id, { observe: 'response' });
	}

	@ListCacheUpdate()
	post(equipment: EquipmentModel): Observable<HttpResponse<EquipmentModel>> {
		return this.http.post<EquipmentModel>(this.url, equipment, { observe: 'response' });
	}

	@ListCacheUpdate()
	put(equipment: EquipmentModel): Observable<HttpResponse<EquipmentModel>> {
		return this.http.put<EquipmentModel>(this.url + '/' + equipment.equipmentId, equipment, { observe: 'response' });
	}

	@ListCacheUpdate()
	patch(url: string, patchDocument: any[]): Observable<any> {
		return this.http.patch(url, patchDocument);
	}

	@ListCacheUpdate()
	delete(ids: string): Observable<any> {
		return this.http.delete(this.url + '/' + ids);
	}
}
