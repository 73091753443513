import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UpDialogRef, UpDialogComponent } from '@ellira/upstrap-dialog';

@Component({
    templateUrl: './confirmation-dialog.component.html'
})
export class ConfirmationDialogComponent extends UpDialogComponent<any> implements OnInit, OnDestroy {

    data: any;
    title: string;
    message: string;
    confirmText: string;
    cancelText: string;
    listenerMethod = this.onKeydown.bind(this)
    replaceValue: any;

    /*     @HostListener('document:keydown', ['$event'])
        onKeydown(e) {
            if (e.key == 'Enter' || e.key == 'Return' || e.keyCode == 13) {
                console.log("ENTER!")
                this.onSuccess(this.data);
            }
            if (e.key == "Escape" || e.code == "Escape") {
                this.onClose(this.data);
            }
        } */

    @ViewChild('submitBtn', { static: true }) submitBtn;

    constructor(public dialogRef: UpDialogRef<ConfirmationDialogComponent>,
        private el: ElementRef) {
        super();
    }

    ngOnInit() {
        document.addEventListener('keydown', this.listenerMethod)

        if (this.data.title) {
            this.title = this.data.title;
        } else {
            this.title = 'Delete';
        }
        if (this.data.message) {
            this.message = this.data.message;
        } else if (this.data.description && this.data.type) {
            this.message = 'Are you sure you want to delete ' + this.data.description + ' from ' + this.data.type + '?';
        } else {
            this.message = 'Are you sure you want to delete ' + this.data.name + ' from ' + this.data.table + '?';
        }
        if (this.data.cancelText) {
            this.cancelText = this.data.cancelText;
        } else {
            this.cancelText = 'Cancel';
        }
        if (this.data.confirmText) {
            this.confirmText = this.data.confirmText;
        } else {
            this.confirmText = 'Delete';
        }
        setTimeout(() => {
            this.submitBtn.elem.nativeElement.firstChild.focus();
        });

        console.log("data: ", this.data);
    }

    onKeydown(e) {
        if (e.key == 'Enter' || e.key == 'Return' || e.keyCode == 13) {
            this.onSuccess(this.data);
        }
        if (e.key == "Escape" || e.code == "Escape") {
            this.onClose(this.data);
        }
    }

    ngOnDestroy() {
        //console.log("dialog close, destroy")
    }

    onClose(data) {
        document.removeEventListener('keydown', this.listenerMethod)
        this.dialogRef.close(data, 'cancel');
    }

    onSuccess(data) {
        document.removeEventListener('keydown', this.listenerMethod);
        data.replaceValue = this.replaceValue;
        this.dialogRef.close(data, this.confirmText.toLowerCase());
    }
}
