import { Injectable, Output, EventEmitter, OnInit, Directive } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { UpAppBarComponent } from '@ellira/upstrap-app-bar';
import { UpDrawerComponent } from '@ellira/upstrap-drawer';
import * as AWS from 'aws-sdk';
import { format } from 'date-fns';

export enum ScreenSize {
    xs = 1,
    sm,
    md,
    lg,
    xl,
    xxl
}


@Directive()
@Injectable({
    providedIn: 'root'
})
export class SharedService implements OnInit {
    // Multiple api url's required only until we switch to a custom domain:
     adminApiUrl = 'https://s6ku23b4ee.execute-api.us-east-1.amazonaws.com/';
     employeesApiUrl = 'https://qsfw2ilh3e.execute-api.us-east-1.amazonaws.com/';
     equipmentsApiUrl = 'https://7410iw88xh.execute-api.us-east-1.amazonaws.com/';
     trailersApiUrl = 'https://cayg2e75mi.execute-api.us-east-1.amazonaws.com/';
     vehiclesApiUrl = 'https://9ij7vtzaz9.execute-api.us-east-1.amazonaws.com/';
     collisionsApiUrl = 'https://djx0smfsog.execute-api.us-east-1.amazonaws.com/';
     formsApiUrl = 'https://3n41p5f421.execute-api.us-east-1.amazonaws.com/';
     vaultsApiUrl = 'https://62bto5gox0.execute-api.us-east-1.amazonaws.com/';
	 branchApiUrl = 'https://ontv1zr3ql.execute-api.us-east-1.amazonaws.com/'

     chatHubUrl = 'https://apidev.moveitms.com/hubs/chat';
     userHubUrl = 'https://apidev.moveitms.com/hubs/users';
    // apiUrl = 'https://localhost:44336/api/';
    // chatHubUrl = 'https://localhost:44336/hubs/chat';
    // userHubUrl = 'https://localhost:44336/hubs/users';
    cognitoIdentityPoolId = 'us-east-1:99964ac0-444d-4c35-bab7-01dab3c3f393';
    cognitoUserPoolClientId = '630km6sbafdg1pmmm6t7lktngl';
    cognitoUserPoolId = 'us-east-1_04aRAtiI3';

    gpsDeviceTypes: Array<{ deviceTypeId: number, description: string }> = Array(
        { "deviceTypeId": 1, "description": "Powered" },
        { "deviceTypeId": 2, "description": "Battery" }
    );

    gpsProviders: Array<{ providerId: number, description: string }> = Array(
        { "providerId": 1, "description": "Geotab" },
        { "providerId": 2, "description": "Ellira" }
    );

    weightClasses: Array<{ weightClassId: number, description: string }> = Array(
        { "weightClassId": 1, "description": "Light Duty" },
        { "weightClassId": 2, "description": "Medium Duty" },
        { "weightClassId": 3, "description": "Heavy Duty" }
    );

    weightUnits = [{id: 'lb'},{id: 'kg'}]
    odometerUnits = [{id: 'km'},{id: 'mi'}]


    public appBar: UpAppBarComponent;
    public returnTo: string;
    public drawer: UpDrawerComponent;

    public isDev: boolean;

    archive: string;

    

    @Output() logoReloadedEvent: EventEmitter<Event> = new EventEmitter<Event>();

    constructor(private router: Router,
        private http: HttpClient) {
        router.events.subscribe((val) => {
            this.setPageTitle(null);
        });

    }

    ngOnInit(){
        this.isDev = this.isDevUrl();
    }

    isDevUrl() : boolean {
        return this.adminApiUrl.includes('apidev') || this.adminApiUrl.includes('localhost:');
    }

    param(input: Object) {
        const searchParams = new URLSearchParams();
        Object.keys(input).forEach(key => searchParams.append(key, input[key]));
        return searchParams.toString();
    }

    formatDate(date, fm?: string): string {
        if (date) {
            if (fm !== undefined) {
                return format(date, fm);
            } else {
                return format(date, 'MMM D[,] YYYY');
            }
        } else {
            return '';
        }
    }

    formatChatDate(date) {
        let d = new Date(date);
        let today = new Date();
        if (d.getDate() == today.getDate() && d.getMonth() == today.getMonth() && d.getFullYear() == today.getFullYear()) {
            return this.formatTime(date);
        } else {
            return this.formatDate(date);
        }
    }

    formatCurrency(value): string {
        if (value) {
            //value = value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            value = (value*1).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            return '$' + value;
        } else {
            return '';
        }
    }

    formatPhone(value) {
        if (value) {
            const phoneNumberString = value.toString();
            const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
            const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
            if (match) {
                return '(' + match[1] + ') ' + match[2] + '-' + match[3];
            }
            return '';
        } else {
            return '';
        }
    }

    formatTime(time) {
        if (time instanceof Date) {
            return this.formatAMPM(time);
        } else if (time !== undefined && time !== '' && typeof time === 'string') {
            const date = new Date(time);
            return this.formatAMPM(date);
        }
    }

    formatAMPM(date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        const strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }

    isMobile() {
        return window.innerWidth <= 768;
    }

    screenSmallerThan(size) {
        return window.innerWidth <= size;
    }

    getGPSDeviceTypes(): { deviceTypeId: number; description: string; }[] {
        return this.gpsDeviceTypes;
    }

    getGPSProviders(): { providerId: number; description: string; }[] {
        return this.gpsProviders;
    }

    getScreenSize(): ScreenSize {
        const width = window.innerWidth;
        const height = window.innerHeight;

        if (width < 576) {
            return ScreenSize.xs;
        } else if (width < 768) {
            return ScreenSize.sm;
        } else if (width < 992) {
            return ScreenSize.md;
        } else if (width < 1200) {
            return ScreenSize.lg;
        } else if (width < 1600) {
            return ScreenSize.xl;
        } else {
            return ScreenSize.xxl;
        }
    }

    getWeightClasses(): { weightClassId: number; description: string; }[] {
        return this.weightClasses;
    }
    
    setPageTitle(pTitle) {
        setTimeout(() => {
            if (this.appBar) {
                this.appBar.setPageTitle(pTitle);
            }
        });
    }

    closeDrawer() {
        if (this.drawer) {
            this.drawer.close();
        }
    }

    drawerOnLogin() {
        if (this.drawer) {
            this.drawer.onLogin();
        }
    }

    drawerSetSurface() {
        if (this.drawer) {
            this.drawer.setSurfaceColor();
        }
    }

    drawerSetPrimary() {
        if (this.drawer) {
            this.drawer.setPrimaryColor();
        }
    }

    drawerSetColorTo(color: string) {
        if (this.drawer) {
            this.drawer.setColorTo(color);
        }
    }

    drawerSetNoPadding() {
        if (this.drawer) {
            this.drawer.setNoPadding();
        }
    }

    // filterContains = this.sharedService.orderBy(myArray, 'productName', 'contains', 'xyz'):
    // filteredList =  this.sharedService.orderBy(filterContains, 'productName', 'lastName, firstName dec');

    filterBy(array, property, operator, value) {
        // array = array to be filtered
        // property = array object property to by
        // operator = operator: '==', '!=', '>', '<', '>=', '<=', contains', 'startswith', 'endswith'
        //                     alias: 'equals', 'notequals', 'greaterthan', 'lessthan', 'greaterorequal', 'lessorequal', 'includes'

        if (operator === '==' || operator === 'equals') {
            return array.filter(x => x[property] === value);
        } else if (operator === '!=' || operator === 'notequals') {
            return array.filter(x => x[property] !== value);
        } else if (operator === '>' || operator === 'greaterthan') {
            return array.filter(x => x[property] > value);
        } else if (operator === '<' || operator === 'lessthan') {
            return array.filter(x => x[property] < value);
        } else if (operator === '>=' || operator === 'greaterorequal') {
            return array.filter(x => x[property] >= value);
        } else if (operator === '<=' || operator === 'lessorequal') {
            return array.filter(x => x[property] <= value);
        } else if (operator === 'contains' || operator === 'includes') {
            return array.filter(x => x[property].toString().toLowerCase().includes(value.toString().toLowerCase()));
        } else if (operator === 'startswith') {
            return array.filter(x => x[property].toString().toLowerCase().startsWith(value.toString().toLowerCase()));
        } else if (operator === 'endswith') {
            return array.filter(x => x[property].toString().toLowerCase().endsWith(value.toString().toLowerCase()));
        } else {
            console.log('error, unidentified operator: ', operator);
        }
    }

    orderBy(array, property, order?) {
        // array = array to be ordered
        // property = array object property to order by
        // order = 'asc' or 'desc' for order (default is 'asc')
        let orderMult = 1;
        if (order === 'desc') { orderMult = -1; }
        return array.sort((a, b) => {
            if (a[property] < b[property]) {
                return (-1 * orderMult);
            } else if (a[property] > b[property]) {
                return (1 * orderMult);
            } else {
                return 0;
            }
        });
    }

    getHours(time): string {
        if (time == null || time === undefined) {
            return '';
        } else {
            const split = time.split(':');
            return split[0] === '00' ? '' : split[0] + 'h';
        }
    }

    getMinutes(time): string {
        if (time == null || time === undefined) {
            return '';
        } else {
            const split = time.split(':');
            return split[1] === '00' ? '' : split[1] + 'm';
        }
    }

    getSeconds(time): string {
        if (time == null || time === undefined) {
            return '';
        } else {
            const split = time.split(':');
            return split[2] === '00' ? '' : split[2].substring(0, 2) + 's';
        }
    }

    convertToISOTime(time) {
        if (time == null) { return null; }
        return new Date(time.getTime() - (time.getTimezoneOffset() * 60000)).toISOString();
    }

    convertFromISOTime(time) {
        if (time == null) { return null; }
        return new Date((new Date(time)).getTime() + ((new Date(time)).getTimezoneOffset() * 60000)).toISOString();
    }

    public resetLogo() {
        this.logoReloadedEvent.emit();
    }

    setLogo(file, user) {
        if (file.type && file.type.includes('image')) {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (_event) => {
                const imgURL = reader.result;
                console.log("set logo 1");
                localStorage.setItem('companyLogo', JSON.stringify(
                    {
                        imgURL: imgURL,
                        userName: user.userName + ''
                    }
                ));
                this.resetLogo();
            }
        } else if (file instanceof ArrayBuffer) {
            var reader = new FileReader();
            var blob = new Blob([file], { type: "image/jpeg" });
            reader.readAsDataURL(blob);
            reader.onload = (_event) => {
                const imgURL = reader.result;
                console.log("set logo 2");
                localStorage.setItem('companyLogo', JSON.stringify(
                    {
                        imgURL: imgURL,
                        userName: user.userName + ''
                    }
                ));
                this.resetLogo();
            }
        }
    }

    fixPhone(phone: string) {
        return phone ? phone.replace(/\D/g,'') : phone;
    }// Sample code for calling a Lambda function:

    sendDebugEmail(error) {
        return;
        AWS.config.region = 'us-east-1';
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: this.cognitoIdentityPoolId
        });
        
        console.log("aws config finished")

        var lambda = new AWS.Lambda({ apiVersion: '2015-03-31' });

        var params = {
            FunctionName: "arn:aws:lambda:us-east-1:376880069927:function:SkyeCdkStack-DebugEmailDebugEmailFunction96BD632F-EnlSvtsmv1TN",
            Payload: JSON.stringify({ "emailAddress" : "truetz@elliratech.com", error: error }), 
        };

        lambda.invoke(params, function (err, data) {
            if (err) {
                console.log(err, err.stack);
            } else {
                console.log(JSON.parse(data.Payload.toString()))
            }
        });
        console.log("lambda invoked")
    }

    validateDate(c: FormControl) {
        const SQL_DATE_REGEX = new RegExp('^[12][0-9]{3}(-|\/)((0[1-9])|(1[0-2]))(-|\/)((0[1-9])|([1-2][0-9])|(3[0-1]))(T|\s)(([0-1][0-9])|(2[0-3])):([0-5][0-9]):([0-5][0-9])');
        return c.value instanceof Date || SQL_DATE_REGEX.test(c.value) ? null : {
            validateDate: {
                valid: false
            }
        };
    }
}
