import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EditFieldDialogComponent } from './edit/edit-field-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationDialogComponent } from './confirmation/confirmation-dialog.component';
import { UpstrapCoreModule } from '@ellira/upstrap-core';
import { UpstrapDialogModule } from '@ellira/upstrap-dialog';
import { UpstrapButtonModule } from '@ellira/upstrap-button';
import { UpstrapInputModule } from '@ellira/upstrap-input';
import { UpstrapDatepickerModule } from '@ellira/upstrap-datepicker';
import { UpstrapNumberBoxModule } from '@ellira/upstrap-number-box';
import { UpstrapLoaderModule } from '@ellira/upstrap-loader';
import { UpstrapSelectModule } from '@ellira/upstrap-select';
import { UpstrapCheckboxModule } from '@ellira/upstrap-checkbox';
import { UpstrapChipModule } from '@ellira/upstrap-chip';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { UpstrapDrawerModule } from '@ellira/upstrap-drawer';
import { AttachGPSDeviceDialogComponent } from './attach-gps-device-dialog/attach-gps-device-dialog.component';
import { GeotabModule } from '../geotab/geotab.module';
import { ChangeGeotabRatePlanDialogComponent } from './change-geotab-rate-plan-dialog/change-geotab-rate-plan-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UpstrapCoreModule,
        UpstrapDialogModule,
        UpstrapButtonModule,
        UpstrapInputModule,
        UpstrapDatepickerModule,
        UpstrapNumberBoxModule,
        UpstrapLoaderModule,
        UpstrapSelectModule,
        UpstrapCheckboxModule,
        UpstrapChipModule,
        VirtualScrollerModule,
        UpstrapDrawerModule,
        GeotabModule
    ],
    declarations: [
        EditFieldDialogComponent,
        ConfirmationDialogComponent,
        AttachGPSDeviceDialogComponent,
		ChangeGeotabRatePlanDialogComponent
    ],
    exports: [
        EditFieldDialogComponent,
        ConfirmationDialogComponent,
        AttachGPSDeviceDialogComponent,
		ChangeGeotabRatePlanDialogComponent
    ],
    entryComponents: [
        EditFieldDialogComponent,
        ConfirmationDialogComponent,
        AttachGPSDeviceDialogComponent,
		ChangeGeotabRatePlanDialogComponent
    ]
})
export class DialogsModule {
}

export * from './edit/edit-field-dialog.component';
export * from './confirmation/confirmation-dialog.component';
