import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import { ICachedObject, ListCacheUpdate } from 'src/app/shared/cache/cache.module';
import { SharedService } from 'src/app/shared/shared.service';
import { NewCacheService } from 'src/app/shared/cache/new-cache.service';
import { UserModel } from './user-models';
import { UserList } from './user-list';

@Injectable({
	providedIn: 'root'
})
export class UserService {

    url = this.sharedService.adminApiUrl + 'users';
    cache: ICachedObject;

    constructor(private http: HttpClient,
        public sharedService: SharedService,
        private cacheService: NewCacheService) {
            this.cache = new ICachedObject;
            this.cache.cacheKey = 'listcache-users';
            this.cache.listId = 'userId';
            this.cache.getFn = this.getList.bind(this);
        }

    getList(params?: any): Observable<HttpResponse<UserList>> {
        return this.http.get<UserList>(params != undefined && Object.keys(params).length != 0? this.url + '?'+ this.sharedService.param(params) : this.url, {observe: 'response'});
    }

    getRoles(): Observable<HttpResponse<any>> {
        return this.http.get<any>(this.sharedService.adminApiUrl + 'roles', { observe: 'response' });
    }

    getPermissions(userId): Observable<HttpResponse<any>> {
        return this.http.get<any>(this.sharedService.adminApiUrl + 'userpermissions/' + userId, { observe: 'response' });
    }

    getCachedList(): Promise<UserModel[]> {
        return this.cacheService.checkCachedList(this.cache);
    }

    checkDuplicate(field: string, duplicate: string, idValue?: number): Observable<HttpResponse<any>> {
        return idValue == null ? this.http.get<any>(this.url + '/exists/' + field + '/' + duplicate, { observe: 'response' }) : this.http.get<any>(this.url + '/exists/' + field + '/' + duplicate + '?' + this.cache.listId + '=' + idValue, { observe: 'response' });
    }

    get(id: number): Observable<HttpResponse<UserModel>> {
        return this.http.get<UserModel>(this.url + '/' + id, {observe: 'response'});
    }

    @ListCacheUpdate()
    post(d: UserModel): Observable<HttpResponse<UserModel>> {
        return this.http.post<UserModel>(this.url, d, { observe: 'response' });
    }

    @ListCacheUpdate()
    put(d: UserModel): Observable<HttpResponse<UserModel>> {
        return this.http.put<UserModel>(this.url + '/' + d.userId, d, { observe: 'response' });
    }

    @ListCacheUpdate()
    patch(url: string, patchDocument: any[]): Observable<any> {
        return this.http.patch(url, patchDocument);
    }

    @ListCacheUpdate()
    delete(ids: string): Observable<any> {
		return this.http.delete(this.url + '/' + ids);
	}
}
