<up-dialog width="500">
    <up-dialog-header>Change Geotab Device Plan</up-dialog-header>
    <up-dialog-body>
        <up-loader spinner *ngIf="!formReady"></up-loader>
		<div #alerts></div>
        <span *ngIf="formReady">
            <form #form="ngForm" novalidate id="ngForm" autocomplete="off" (ngSubmit)="save()" [formGroup]="detailForm">
                <button type="submit" style="display:none">Submit</button>
                
				<up-select *ngIf="geotabRatePlans" [disabled]="user.editLevel('geotabrateplanchange', securityGroups) < 2" formControlName="geotabDevicePlanId" [formData]="detailForm.get('geotabDevicePlanId')" [dataSource]="geotabRatePlans" valueField="devicePlanId" [textField]="['skyeName', ' (', 'formattedPrice', ')']" label="Device Plan" filterable></up-select>
				<div class="form-group">
					<p *ngIf="user.editLevel('geotabrateplanchange', securityGroups) == 2">Device plan changes take several minutes to update in Geotab</p>
					<p *ngIf="user.editLevel('geotabrateplanchange', securityGroups) < 2">Insufficent permissions to change Geotab device plan</p>
				</div>
			</form>
        </span>

    </up-dialog-body>
    <up-dialog-footer>
        <span>
            <up-button (click)="onClose()">Cancel</up-button>
            <up-button (click)="save()" [disabled]="!formReady || !detailForm.valid" filled>Save</up-button>
        </span>
    </up-dialog-footer>
</up-dialog>