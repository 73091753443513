import { Injectable } from '@angular/core';
import { shareReplay, tap } from 'rxjs/operators';
import { get, set, del } from 'idb-keyval';
import { Observable } from 'rxjs';
const pako = require('pako');

export class ICachedObject {
    cachedList?: any[];
    listId: string;
    cacheKey: string;
    getFn: Function;
    maxAge?: number; // Cache expiry time in milliseconds (default 8 hours)
    expiry?: Date; // Not an input, set based on maxAge
    orderBy?: any; // Property to sort list by (used for updating)
}

// Update decorator:
export function ListCacheUpdate(updateSecurityGroup?: boolean): MethodDecorator {
    return function (target: Function, key: string, descriptor: any) {
        //console.log("key: ", key)
        //console.log("descriptor: ", descriptor)
        const originalMethod = descriptor.value;
        descriptor.value = function (...args: any[]) {
            const response = (originalMethod.call(this, ...args) as Observable<any>).pipe(
                tap(resp => {
                    this.cacheService.updateCache(key, this.cache, resp, args);

                    if (updateSecurityGroup) {
                        //console.log("ListCacheUpdate, updateSG")
                        this.securityGroupService.updateCachedList();
                        //console.log("this.cache: ", this.cache);
                    }
                }),
                shareReplay()
            );
            return response;
        }
        return descriptor;
    }
}

@Injectable()
export class NewCacheService {

    debug = false;

    constructor() { }

    checkCachedList(cache: ICachedObject): Promise<any[]> {
        return new Promise((resolve, reject) => {
            this.debug ? console.log("check: ", cache) : null;
            get(cache.cacheKey)
                .then((value) => {
                    //this.debug ? console.log("value: ", value) : null;
                    if (value == undefined) {
                        // List does not exist, set it from API:
                        this.debug ? console.log("list doesn't exist, set it from api") : null;
                        resolve(this.setCachedList(cache));
                    } else {
                        let val = JSON.parse(<any>value);
                        // Check expiry:
                        this.debug ? console.log("check expiry") : null;
                        if (new Date(Date.now()) > new Date(cache.expiry)) {
                            // Expired cache, reset, go to API:
                            resolve(this.setCachedList(cache));
                        } else {
                            // Set list from cache
                            this.debug ? console.log("set list form  cache") : null;
                            cache.cachedList = val.cachedList.filter(x => x != null);
                            cache.expiry = val.expiry;
                            if (cache.orderBy) {
                                var copy = JSON.parse(JSON.stringify(cache.cachedList))
                                copy = copy.sort((a, b) => {
                                    if (a == null) {
                                        return -1
                                    } else if (b == null) {
                                        return 1
                                    }
                                    if (typeof a[cache.orderBy] == 'string') {
                                        return a[cache.orderBy].localeCompare(b[cache.orderBy])
                                    } else {
                                        return a[cache.orderBy] > b[cache.orderBy] ? 1 : -1
                                    }
                                })
                                cache.cachedList = JSON.parse(JSON.stringify(copy)).filter(x => x != null)
                            }
                            resolve(cache.cachedList);
                        }
                    }
                })
                .catch(err => {
                    console.log("error: ", err);
                    reject();
                });
        });
    }

    clearCachedList(cache): Promise<boolean> {
        return new Promise((resolve, reject) => {
            del(cache.cacheKey)
                .then(val => {
                    cache.cachedList = null;
                    resolve(true);
                })
                .catch(err => {
                    cache.cachedList = null;
                    console.log("error: ", err);
                    resolve(false);
                });
        });
    }

    async updateCache(type, cache, response, args?) {
        if (cache == undefined || cache.cachedList == null || cache.cachedList == undefined) {
            const hasCache = await this.checkCachedList(cache);
            if (hasCache) {
                this.updateCacheFull(type, cache, response, args);
            } else { return; }
        } else {
            this.updateCacheFull(type, cache, response, args);
        }

    }

    updateCacheFull(type, cache, response, args?) {
        switch (type) {
            case 'post':
                cache.cachedList.push(response.body);
                if (cache.orderBy) {
                    var copy = JSON.parse(JSON.stringify(cache.cachedList))
                    copy = copy.sort((a, b) => {
                        if (a == null) {
                            return -1
                        } else if (b == null) {
                            return 1
                        }
                        if (typeof a[cache.orderBy] == 'string') {
                            return a[cache.orderBy].localeCompare(b[cache.orderBy])
                        } else {
                            return a[cache.orderBy] > b[cache.orderBy] ? 1 : -1
                        }
                    })
                    cache.cachedList = JSON.parse(JSON.stringify(copy)).filter(x => x != null)
                }
                // Update saved data:
                this.saveCachedList(cache);
                break;
            case 'put':
                var lastslashindex = response.url.lastIndexOf('/');
                var urlId = response.url.substring(lastslashindex + 1);
                var existing = cache.cachedList.find(x => x[cache.listId] == urlId);
                if (existing && args) {
                    Object.assign(existing, args[0]);
                    if (cache.orderBy) {
                        var copy = JSON.parse(JSON.stringify(cache.cachedList))
                        copy = copy.sort((a, b) => {
                            if (typeof a[cache.orderBy] == 'string') {
                                return a[cache.orderBy].localeCompare(b[cache.orderBy])
                            } else {
                                return a[cache.orderBy] > b[cache.orderBy] ? 1 : -1
                            }
                        })
                        cache.cachedList = JSON.parse(JSON.stringify(copy)).filter(x => x != null)
                    }
                    // Update saved data:
                    this.saveCachedList(cache);
                } else {
                    console.log("error, existing put not found");
                }
                break;
            case 'patch':
                // No way to locate cached Object from patch, because there is no urlId, just the ID number
                /* var lastslashindex = args[0].lastIndexOf('/');
                var urlId = args[0].substring(lastslashindex + 1);
                var existingPatch = cache.cachedList.find(x => x[cache.listId] == urlId);
                if (existingPatch) {
                    args[1].forEach((x, index) => {
                        let fieldName = x.path.substring(1);
                        existingPatch[fieldName] = x.value;
                        if (index == args[1].length - 1) {
                            // Update saved data:
                            this.saveCachedList(cache); 
                        }
                    })
                } else {
                    console.log("error, existing patch not found");
                } */
                break;
            case 'delete':
                var urlId = args[0]
                if (typeof urlId == 'string') {
                    urlId = urlId.split('?')[0]
                }
                var existingDelete = cache.cachedList.find(x => x[cache.listId] == urlId);
                if (existingDelete) {
                    var copy = JSON.parse(JSON.stringify(cache.cachedList))
                    copy = copy.filter(x => x[cache.listId] != urlId);
                    cache.cachedList = JSON.parse(JSON.stringify(copy)).filter(x => x != null)
                    // Update saved data:
                    this.saveCachedList(cache);
                } else {
                    console.log("error, existing delete not found");
                    console.log("args: ", args);
                    console.log("urlId: ", urlId);
                    console.log("existingDelete: ", existingDelete)
                }
                break;
        }
    }

    public setCachedList(cache: ICachedObject, isDebug?: boolean): Promise<any[]> {
        isDebug ? console.log("setCachedList(): ", cache) : null;
        this.debug ? console.log("setCachedList(): ", cache) : null;
        return new Promise((resolve, reject) => {
            cache.getFn({ cache: !isDebug }).subscribe(
                resp => {
                    isDebug ? console.log("getFn resp: ", resp) : null;

                    this.debug ? console.log("getFn resp: ", resp) : null;
                    //console.log("getFn resp: ", resp);
			  
                    //const decompressedBody = pako.inflate(compressedBody);
                    //const decompressedBody = inflateRawSync(Buffer.from(compressedBody, 'base64')).toString();

                    //const decompressedBody = zlib.gunzipSync(Buffer.from(compressedBody, 'base64')).toString('utf8');
                    // const decompressedBody = lz4.decode(compressedBody);


                    //const decompressedBody = zlib.inflate(compressedBody)

                    //const decompressedBody = pako.inflate(new Uint8Array(compressedBody), { to: 'string' });
                    const compressedBody = resp.body.results;
                    const decodedBody = atob(compressedBody);
                    const uint8Array = new Uint8Array(decodedBody.length);

                    for (let i = 0; i < decodedBody.length; ++i) {
                    uint8Array[i] = decodedBody.charCodeAt(i);
                    }

                    const decompressedBody = pako.inflate(uint8Array, { to: 'string' });

                    //console.log("decompressedBody: ", decompressedBody);
                    cache.cachedList = JSON.parse(decompressedBody);
                    cache.cachedList = cache.cachedList.filter(x => x != null)
                    if (cache.orderBy) {
                        var copy = JSON.parse(JSON.stringify(cache.cachedList))
                        //console.log("copy: ", copy);
                        //console.log("orderBy: ", cache.orderBy)

                        copy = copy.sort((a, b) => {

                            if (a == null) {
                                return -1
                            } else if (b == null) {
                                return 1
                            }
                            if (typeof a[cache.orderBy] == 'string') {
                                return a[cache.orderBy].localeCompare(b[cache.orderBy])
                            } else {
                                return a[cache.orderBy] > b[cache.orderBy] ? 1 : -1
                            }
                        })
                        cache.cachedList = JSON.parse(JSON.stringify(copy)).filter(x => x != null)
                    }
                    resolve(this.saveCachedList(cache));
                },
                error => {
                    cache.cachedList = null;
                    console.log("getFn error: ", error);
                    reject();
                }
            )
        });
    }

    private saveCachedList(cache: ICachedObject, isDebug?: boolean): Promise<any[]> {
        this.debug || isDebug ? console.log("saveCachedList: ", cache) : null;
        return new Promise((resolve, reject) => {
            if (cache.maxAge) {
                var dateObj = Date.now();
                dateObj += cache.maxAge;
                var expiry = new Date(dateObj);
                cache.expiry = expiry;
            } else {
                // Default to 8 hours:
                var dateObj = Date.now();
                dateObj += 28800000;
                var expiry = new Date(dateObj);
                cache.expiry = expiry;
            }
            set(cache.cacheKey, JSON.stringify(cache))
                .then(val => {
                    this.debug || isDebug ? console.log("set complete") : null;
                    if (cache.orderBy) {
                        var copy = JSON.parse(JSON.stringify(cache.cachedList))
                        copy = copy.sort((a, b) => {

                            if (a == null) {
                                return -1
                            } else if (b == null) {
                                return 1
                            }
                            if (typeof a[cache.orderBy] == 'string') {
                                return a[cache.orderBy].localeCompare(b[cache.orderBy])
                            } else {
                                return a[cache.orderBy] > b[cache.orderBy] ? 1 : -1
                            }
                        })
                        cache.cachedList = JSON.parse(JSON.stringify(copy)).filter(x => x != null)
                    }
                    resolve(cache.cachedList);
                })
                .catch(err => {
                    console.log("error: ", err);
                    reject();
                });
        });
    }
}