import { EventEmitter, Injectable } from '@angular/core';
import { AppUserAuth } from './app-user-auth';
import { AppUser } from './app-user';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedService } from '../shared.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserService } from '../../admin/user/user.service';
import { Location } from '@angular/common';

import Amplify, { Auth } from 'aws-amplify';
import { UserBaseModel } from 'src/app/admin/user/user-models';

@Injectable()
export class SecurityService {
    user: AppUserAuth = new AppUserAuth();
    userModel: UserBaseModel;
    hasFilledAuthData = false;
    isUserModelReady = false;

    public userModelReady = new EventEmitter<UserBaseModel>();

    constructor(private location: Location, private router: Router, private activatedRoute: ActivatedRoute, private userService: UserService, public sharedService: SharedService) {

        console.log("securityService constructor")
        Amplify.configure({
            Auth: {
                identityPoolId: sharedService.cognitoIdentityPoolId,
                region: 'us-east-1',
                userPoolId: sharedService.cognitoUserPoolId,
                userPoolWebClientId: sharedService.cognitoUserPoolClientId,
                mandatorySignIn: false,

                // OPTIONAL - Configuration for cookie storage
                // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
                /*
                cookieStorage: {
                    // REQUIRED - Cookie domain (only required if cookieStorage is provided)
                    domain: 'http://localhost:4004',
                    // OPTIONAL - Cookie path
                    path: '/',
                    // OPTIONAL - Cookie expiration in days
                    expires: 365,
                    // OPTIONAL - Cookie secure flag
                    // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
                    secure: true
                },*/

                /*
                // OPTIONAL - Hosted UI configuration
                 oauth: {
                    domain: 'your_cognito_domain',
                    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
                    redirectSignIn: 'http://localhost:3000/',
                    redirectSignOut: 'http://localhost:3000/',
                    responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
                }
                */
            }
        });

        // You can get the current config object
        //const currentConfig = Auth.configure();

        this.fillAuthData();
    }

    async login(entity: AppUser): Promise<AppUserAuth> {
        return new Promise(async (resolve, reject) => {
            try {
                await Auth.signIn(entity.userName, entity.password).then(signInUser => {
                    console.log("signInUser: ", signInUser);
                    if (signInUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
                        reject({ challenge: 'NEW_PASSWORD_REQUIRED', signInUser: signInUser })
                    }
                    Auth.currentSession().then(async res => {
                        localStorage.setItem('bearerToken', res.getIdToken().getJwtToken());
                        const userIdToken = res.getIdToken().decodePayload();
                        console.log("userIdToken: ", userIdToken)
                        const userId = userIdToken.userId ? parseInt(userIdToken.userId) : null
                        const userData = userIdToken.userData ? userIdToken.userData : null
                        const permissions = await this.getUserPermissions(userId);
                        const userResponse = await this.getUser(userId);
                        console.log("userResponse: ", userResponse);
                        try {
                            console.log("setUser 2: ", userData);

                            await this.setUser(userResponse);
                            //await this.getUser(userId);
                            //await this.updateUserLoginTime(userId);
                            localStorage.setItem('loggedInUserId', JSON.stringify(userId));
                            console.log("userIdToken: ", userIdToken);
                            const newUser = new AppUserAuth(userIdToken);
                            newUser.isAuthenticated = true;
                            Object.assign(this.user, newUser);
                            localStorage.setItem('authorizationData', JSON.stringify(newUser));
                            resolve(newUser);
                        } catch (error) {
                            console.log
                            reject(error);
                        }
                    }).catch(error => {
                        reject(error);
                    });
                }).catch(e => {
                    console.log('Auth sign in error: ', e);
                    if (e.code == 'PasswordResetRequiredException') {
                        Auth.forgotPassword(entity.userName);
                    } else if (e.code == 'NotAuthorizedException' || e.code == 'NotAuthorizedException: Incorrect username or password.') {
                        console.log("do forgot password")
                        Auth.forgotPassword(entity.userName).then(data => {
                            console.log("forgot data: ", data)
                        }).catch(err => {
                            console.log("forgot error: ", err);
                        });
                    }
                    reject(e);
                });
            } catch (error) {
                reject();
            }
        });
    }

    getUserPermissions(userId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.userService.getPermissions(userId).subscribe(
                resp => {
                    console.log("userPermissions resp: ", resp);
                    resolve(resp.body);
                },
                error => {
                    reject();
                }
            )
        });
    }

    resendCode(username): Promise<any> {
        return new Promise((resolve, reject) => {
            Auth.forgotPassword(username).then(
                resp => {
                    resolve(resp);
                }
            ).catch(
                error => {
                    reject(error);
                }
            );
        });
    }

    setPassword(user, password): Promise<any> {
        return new Promise((resolve, reject) => {
           
            Auth.completeNewPassword(user, password, []).then(
                resp => {
                    console.log("resp: ", resp);
                    Auth.currentSession().then(async res => {
                        localStorage.setItem('bearerToken', res.getIdToken().getJwtToken());
                        const userIdToken = res.getIdToken().decodePayload();
                        const userId = userIdToken.userId ? parseInt(userIdToken.userId) : null
                        const userData = userIdToken.userData ? userIdToken.userData : null
                        const permissions = await this.getUserPermissions(userId);
                        
                        const userResponse = await this.getUser(userId);
                        console.log("userResponse: ", userResponse);
                        try {
                            console.log("setUser 3: ", userData);

                            await this.setUser(userResponse);
                            //await this.getUser(userId);
                            //await this.updateUserLoginTime(userId);
                            localStorage.setItem('loggedInUserId', JSON.stringify(userId));
                            const newUser = new AppUserAuth(userIdToken);
                            newUser.isAuthenticated = true;
                            Object.assign(this.user, newUser);
                            localStorage.setItem('authorizationData', JSON.stringify(newUser));
                            resolve(newUser);
                        } catch (error) {
                            console.log
                            reject(error);
                        }
                    }).catch(error => {
                        console.log("setPassword error: ", error)
                        reject(error);
                    });
                }
            ).catch(
                error => {
                    console.log("setPassword error2: ", error)
                    reject(error);
                }
            );

        });
    }

    changePassword(username, oldPassword, newPassword): Promise<any> {
        return new Promise((resolve, reject) => {
            Auth.currentAuthenticatedUser()
                .then(user => {
                    Auth.changePassword(user, oldPassword, newPassword).then(
                        resp => {
                            resolve(resp);
                        }
                    ).catch(
                        error => {
                            reject(error);
                        }
                    );
                }).catch(
                    error => {
                        reject(error);
                    }
                )
        });
    }

    submitForgotPassword(username, password, code): Promise<any> {
        return new Promise((resolve, reject) => {
            Auth.forgotPasswordSubmit(username, code, password).then(
                resp => {
                    resolve(resp);
                }
            ).catch(
                error => {
                    reject(error);
                }
            );
        });
    }

    updateUserLoginTime(id): Promise<any> {
        return new Promise((resolve, reject) => {
            const patchDoc = [{ 'op': 'replace', 'path': '/' + 'lastLoginTime', 'value': new Date() }];
            this.userService.patch(this.sharedService.adminApiUrl + '/users/' + id, patchDoc).subscribe(
                resp => {
                    resolve(true);
                },
                error => {
                    reject(error);
                });

        })
    }

    updateUserLogoutTime(id): Promise<any> {
        return new Promise((resolve, reject) => {
            const patchDoc = [{ 'op': 'replace', 'path': '/' + 'lastLogoutTime', 'value': new Date() }];
            this.userService.patch(this.sharedService.adminApiUrl + '/users/' + id, patchDoc).subscribe(
                resp => {
                    console.log("patch resp: ", resp);
                    resolve(true);
                },
                error => {
                    reject(error);
                });

        })
    }

    setUser(userData) {
        return new Promise((resolve, reject) => {
            this.userModel = JSON.parse(JSON.stringify(userData));
            this.user.userModel = JSON.parse(JSON.stringify(this.userModel));
            console.log("user.userModel: ", this.user.userModel)
            console.log("security set user: ", this.user);
            this.isUserModelReady = true;
            setTimeout(() => {
                console.log("userModel: ", this.userModel)
                this.userModelReady.emit(this.user.userModel);
            });
            
            resolve(this.userModel);
        })
    }

    public getUser(id): Promise<any> {
        return new Promise((resolve, reject) => {
            this.userService.get(id).subscribe(
                resp => {
                    this.userModel = resp.body;
                    this.user.userModel = JSON.parse(JSON.stringify(this.userModel));
                    this.isUserModelReady = true;
                    this.userModelReady.emit(this.user.userModel);
                    resolve(this.userModel);
                },
                error => {
                    reject(error);
                });

        })
    }

    async logout() {
        const userId = JSON.parse(localStorage.getItem('loggedInUserId'))
        if (userId) {
            // await this.updateUserLogoutTime(this.user.userModel.userId)
        }
        this.resetUser();
        this.router.navigate(['/login']);
    }

    resetUser(): void {
        this.user.userName = '';
        this.user.bearerToken = '';
        this.user.isAuthenticated = false;
        this.user.claims = [];
        localStorage.removeItem('bearerToken');
        localStorage.removeItem('authorizationData');
    }

    fillAuthData(): Promise<any> {
        return new Promise((resolve, reject) => {

            // load the user data if we have one stored.
            const helper = new JwtHelperService();
            const token = localStorage.getItem('bearerToken');
            if (token) {
                const isExpired = helper.isTokenExpired(token);
                if (isExpired) {
                    this.logout();
                    reject()
                } else {
                    const authData = localStorage.getItem('authorizationData');
                    if (authData) {
                        const u = JSON.parse(authData);
                        Object.assign(this.user, u);
                        setTimeout(async () => {
                            if (!this.hasFilledAuthData && this.user.claimValue('userId') !== undefined) {
                                this.hasFilledAuthData = true;

                                const permissions = await this.getUserPermissions(this.user.claimValue('userId'));

                                const userResponse = await this.getUser(this.user.claimValue('userId'));
                                console.log("userResponse: ", userResponse);
                                console.log("setUser 1: ", this.user.claimValue('userData'));
                                await this.setUser(userResponse)
                                //await this.getUser(this.user.claimValue('userId'));
                                resolve(true);
                            }
                        });
                    } else {
                        this.logout();
                        reject(false)
                    }
                }
            } else {
                if (this.location.path() == '/sign-up') {
                    return;
                } else if (this.location.path().includes('/login/forgot-password')) {
                    return;
                } else {
                    this.logout();
                    return;
                }
            }
        });
    }
}
