import { Component, OnInit, ChangeDetectorRef, ViewChild, HostListener } from '@angular/core';
import { AppUserAuth } from 'src/app/shared/security/app-user-auth';
import { Subscription } from 'rxjs';
import { SecurityService } from 'src/app/shared/security/security.service';
import { UserService } from '../../user.service';
import { UpDialogComponent, UpDialogRef } from '@ellira/upstrap-dialog';
import { IAlertOptions, UpAlertService } from '@ellira/upstrap-alert';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { upConfirmedValidator, upPasswordStrengthValidator } from '@ellira/upstrap-input';

export interface PasswordData {
    password: string;
}

@Component({
    templateUrl: './password-dialog.component.html'
})
export class PasswordDialogComponent extends UpDialogComponent<PasswordData> implements OnInit {
    data: any;
    user: AppUserAuth;
    dialogSub: Subscription;

    formReady = false;
    saving = false;

    username: string;

    passwordForm: FormGroup;

    @ViewChild('alerts', {static: false}) alerts;

    @HostListener('document:keydown', ['$event'])
    onKeydown(e) {
        if (e.key == "Escape" || e.code == "Escape") {
            this.onClose();
        }
    }

    constructor(public dialogRef: UpDialogRef<PasswordDialogComponent>,
        private security: SecurityService,
        private alertService: UpAlertService,
        private fb: FormBuilder,
        private securityService: SecurityService,
        private ds: UserService,
        private changeDetecter: ChangeDetectorRef) {
        super();
    }

    ngOnInit() {
        this.user = this.security.user;
        
        Promise.resolve(null).then(() => {
            this.initialize();
        });
    }

    async initialize() {
        console.log("data: ", this.data);
        if (this.data.username) {
            this.username = this.data.username;
        } else {
            this.username = this.user.userName
        }
        console.log("username: ", this.username);
        this.changeDetecter.detectChanges();

        this.passwordForm = this.fb.group({
            oldPassword: ['', [Validators.required]],
            password: ['', [Validators.required, upPasswordStrengthValidator]],
            confirmPassword: ['', [Validators.required]]
        }, { 
            validator: upConfirmedValidator('password', 'confirmPassword')
        });

        this.formReady = true;
    }

    onClose() {
        this.dialogRef.close(this.data, 'cancel');
    }

    async save() {
        this.saving = true;
        if (this.passwordForm.get('password').value == this.passwordForm.get('confirmPassword').value) {
            const saveSuccess = await this.securityService.changePassword(this.username, this.passwordForm.get('oldPassword').value, this.passwordForm.get('password').value);
            if (saveSuccess) {
                this.dialogRef.close(this.data, 'saved');
                this.saving = false;
            } else {
                console.log("error saving: ", saveSuccess);
                this.createAlert(null, 'Error changing password');
                this.saving = false;
            }
        } else {
            this.createAlert(null, 'Passwords do not match');
            this.saving = false;
        }
    }

    // Alert:
    createAlert(title, message) {
        let options: IAlertOptions = {
            title: title,
            content: message,
            dismissible: true,
            duration: 5,
            color: 'danger',
            alertError: true
        }
        this.alertService.show(this.alerts, options);
    }
}
