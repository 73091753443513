import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
	{ path: 'admin/claim', loadChildren: () => import('./admin/claim/claim-admin.module').then(mod => mod.ClaimAdminModule) },

	{ path: 'admin/collision', loadChildren: () => import('./admin/collision/collision-admin.module').then(mod => mod.CollisionAdminModule) },
	{ path: 'admin/company', loadChildren: () => import('./admin/company/company-routing.module').then(mod => mod.CompanyRoutingModule)},
	{ path: 'admin/branchadmin', loadChildren: () => import('./admin/branch-admin/branch-admin.module').then(mod => mod.BranchAdminModule) },

	{ path: 'admin/employee', loadChildren: () => import('./admin/employee/employee-admin.module').then(mod => mod.EmployeeAdminModule) },
	{ path: 'admin/equipment', loadChildren: () => import('./admin/equipment/equipment-admin.module').then(mod => mod.EquipmentAdminModule) },
	//{ path: 'admin/vault', loadChildren: () => import('./admin/vault/vault-admin.module').then(mod => mod.VaultAdminModule) },
	{ path: 'admin/scheduling', loadChildren: () => import('./admin/scheduling/scheduling-admin/scheduling-admin.module').then(mod => mod.SchedulingAdminModule) },
	{ path: 'admin/security-group', loadChildren: () => import('./admin/security-group/security-group.module').then(mod => mod.SecurityGroupModule) },
	{ path: 'admin/trailer', loadChildren: () => import('./admin/trailer/trailer-admin.module').then(mod => mod.TrailerAdminModule) },
	{ path: 'admin/vehicle', loadChildren: () => import('./admin/vehicle/vehicle-admin.module').then(mod => mod.VehicleAdminModule) },
	{ path: 'admin/user', loadChildren: () => import('./admin/user/user-routing.module').then(mod => mod.UserRoutingModule)},
	{ path: 'admin/branch', loadChildren: () => import('./admin/branch/branch-routing.module').then(mod => mod.BranchRoutingModule)},
	{ path: 'drivers', loadChildren: () => import('./drivers/drivers-routing.module').then(mod => mod.DriversRoutingModule)},
	{ path: 'tasks', loadChildren: () => import('./tasks/tasks.module').then(mod => mod.TasksModule) },
	{ path: 'claims', loadChildren: () => import('./claim/claim-routing.module').then(mod => mod.ClaimRoutingModule) },
	{ path: 'collisions', loadChildren: () => import('./collision/collision-routing.module').then(mod => mod.CollisionRoutingModule) },
	{ path: 'employees', loadChildren: () => import('./employee/employee-routing.module').then(mod => mod.EmployeeRoutingModule) },
	{ path: 'equipment', loadChildren: () => import('./equipment/equipment-routing.module').then(mod => mod.EquipmentRoutingModule) },	
	{ path: 'trailers', loadChildren: () => import('./trailer/trailer-routing.module').then(mod => mod.TrailerRoutingModule) },
	{ path: 'vault', loadChildren: () => import('./vault/vault-routing.module').then(mod => mod.VaultRoutingModule) },	
	{ path: 'vehicles', loadChildren: () => import('./vehicle/vehicle-routing.module').then(mod => mod.VehicleRoutingModule) },
	{ path: 'warehouse', loadChildren: () => import('./vault/warehouse/warehouse-routing.module').then(mod => mod.WarehouseRoutingModule) },	
	{ path: 'login', loadChildren: () => import('./shared/security/login/login.module').then(mod => mod.LoginModule) },
	{ path: 'sign-up', loadChildren: () => import('./sign-up-wizard/sign-up-wizard-routing.module').then(mod => mod.SignUpWizardRoutingModule) },
	{ path: 'skye-drive', loadChildren: () => import('./skye-drive/skye-drive.module').then(mod => mod.SkyeDriveModule) },
	{ path: '', redirectTo: 'login', pathMatch: 'full' },
	{ path: '**', redirectTo: '404', pathMatch: 'full' }
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
