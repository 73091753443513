<up-dialog>
    <up-dialog-header>Edit User Startup Screen</up-dialog-header>
    <up-dialog-body>
        <up-loader spinner *ngIf="!formReady"></up-loader>
        <span *ngIf="formReady">
            <div class="form-contents">
                <div class="row">
                    <div class="col-sm">
                        <up-select [(ngModel)]="startupScreen" [dataSource]="startPages" valueField="id" textField="text" label="Startup Screen" [showClearButton]="false"></up-select>
                    </div>
                </div>
            </div>
        </span>
    </up-dialog-body>
    <up-dialog-footer>
        <span *ngIf="formReady">
            <up-button (click)="onClose()">Cancel</up-button>
            <up-button (click)="save()" (keyup.enter)="save()" filled>Save</up-button>
        </span>
    </up-dialog-footer>
</up-dialog>