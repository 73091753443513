import { Component, HostListener, OnInit } from '@angular/core';
import { GeoTabService } from '../../geotab.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UpDialogComponent, UpDialogRef } from '@ellira/upstrap-dialog';

@Component({
    templateUrl: './geofence-detail-dialog.component.html'
})
export class GeofenceDetailDialogComponent extends UpDialogComponent<any> implements OnInit {

    geofenceDetailForm: FormGroup;
    data: any;
    zoneTypes: any;
    formReady: boolean = false;

    @HostListener('document:keydown', ['$event'])
    onKeydown(e) {
        if (e.key == "Escape" || e.code == "Escape") {
            this.onClose(this.data);
        }
    }

    constructor(public dialogRef: UpDialogRef<GeofenceDetailDialogComponent>,
        private geoTabService: GeoTabService,
        private fb: FormBuilder) {
        super();
    }

    ngOnInit() {
        this.loadZoneList();
    }

    async loadZoneList() {
        const zoneListReady = await this.geoTabService.loadZoneListData();

        if (zoneListReady) {
            this.zoneTypes = this.geoTabService.zoneList;
            this.initForm();
        } else {
            console.log("error getting zone list");
            this.onClose(this.data);
        }
    }

    initForm() {
        this.geofenceDetailForm = this.fb.group({
            zoneName: [this.data.zoneName, [Validators.required, Validators.maxLength(50)]],
            zoneTypeId: [this.data.zoneTypeId, [Validators.required]]
        });
        this.formReady = true;
    }

    onClose(data) {
        this.dialogRef.close(data, 'cancel');
    }

    onDelete(data) {
        this.dialogRef.close(data, 'clear');
    }

    onSuccess(data) {
        Object.assign(this.data, this.geofenceDetailForm.value);
        this.dialogRef.close(data, 'save');
    }

    save() {
        
    }
}
