import { Injectable } from '@angular/core';
import { shareReplay, tap } from 'rxjs/operators';
import { get, set, del } from 'idb-keyval';
import { Observable } from 'rxjs';



export class ICachedObject {
    cachedList?: any[];
    listId: string;
    cacheKey: string;
    getFn: Function;
    maxAge?: number; // Cache expiry time in milliseconds (default 8 hours)
    expiry?: Date; // Not an input, set based on maxAge
    orderBy?: any; // Property to sort list by (used for updating)
}

// Update decorator:
export function ListCacheUpdate(): MethodDecorator {
    return function (target: Function, key: string, descriptor: any) {
        const originalMethod = descriptor.value;
        descriptor.value = function (...args: any[]) {
            const response = (originalMethod.call(this, ...args) as Observable<any>).pipe(
                tap(resp => {
                    this.cacheService.updateCache(key, this.cache, resp, args);
                }),
                shareReplay()
            );
            return response;
        }
        return descriptor;
    }
}

@Injectable()
export class CacheService {

    debug = false;

    constructor() { }

    checkCachedList(cache: ICachedObject): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.debug ? console.log("check: ", cache) : null;
            get(cache.cacheKey)
                .then((value) => {
                    //this.debug ? console.log("value: ", value) : null;
                    if (value == undefined) {
                        // List does not exist, set it from API:
                        this.debug ? console.log("list doesn't exist, set it from api") : null;
                        resolve(this.setCachedList(cache));
                    } else {
                        let val = JSON.parse(<any>value);
                        // Check expiry:
                        this.debug ? console.log("check expiry") : null;
                        if (new Date(Date.now()) > new Date(cache.expiry)) {
                            // Expired cache, reset, go to API:
                            resolve(this.setCachedList(cache));
                        } else {
                            // Set list from cache
                            this.debug ? console.log("set list form  cache") : null;
                            cache.cachedList = val.cachedList;
                            cache.expiry = val.expiry;
                            resolve(true);
                        }
                    }
                })
                .catch(err => {
                    console.log("error: ", err);
                    resolve(false);
                });
        });
    }

    clearCachedList(cache): Promise<boolean> {
        return new Promise((resolve, reject) => {
            del(cache.cacheKey)
                .then(val => {
                    cache.cachedList = null;
                    resolve(true);
                })
                .catch(err => {
                    cache.cachedList = null;
                    console.log("error: ", err);
                    resolve(false);
                });
        });
    }

    async updateCache(type, cache, response, args?) {
        if (cache == undefined || cache.cachedList == null ||  cache.cachedList == undefined) {
            const hasCache = await this.checkCachedList(cache);
            if (hasCache) {
                this.updateCacheFull(type, cache, response, args);
            } else { return; }
        } else {
            this.updateCacheFull(type, cache, response, args);
        }
        
    }

    updateCacheFull(type, cache, response, args?) {
        switch (type) {
            case 'post':
                cache.cachedList.push(response.body);
                if (cache.orderBy) {
                    cache.cachedList.sort((a,b) => a[cache.orderBy] > b[cache.orderBy] ? 1 : -1);
                }
                // Update saved data:
                this.saveCachedList(cache);                
                break;
            case 'put':
                var lastslashindex = response.url.lastIndexOf('/');
                var urlId = response.url.substring(lastslashindex + 1);
                var existing = cache.cachedList.find(x => x[cache.listId] == urlId);
                if (existing && args) {
                    Object.assign(existing, args[0]);
                    if (cache.orderBy) {
                        cache.cachedList.sort((a,b) => a[cache.orderBy] > b[cache.orderBy] ? 1 : -1);
                    }
                    // Update saved data:
                    this.saveCachedList(cache); 
                } else {
                    console.log("error, existing put not found");
                }
                break;
            case 'patch':
                // No way to locate cached Object from patch, because there is no urlId, just the ID number
                /* var lastslashindex = args[0].lastIndexOf('/');
                var urlId = args[0].substring(lastslashindex + 1);
                var existingPatch = cache.cachedList.find(x => x[cache.listId] == urlId);
                if (existingPatch) {
                    args[1].forEach((x, index) => {
                        let fieldName = x.path.substring(1);
                        existingPatch[fieldName] = x.value;
                        if (index == args[1].length - 1) {
                            // Update saved data:
                            this.saveCachedList(cache); 
                        }
                    })
                } else {
                    console.log("error, existing patch not found");
                } */
                break;
            case 'delete':
                var lastslashindex = args[0].lastIndexOf('/');
                var urlId = args[0].substring(lastslashindex + 1);
                var existingDelete = cache.cachedList.find(x => x[cache.listId] == urlId);
                if (existingDelete) {
                    cache.cachedList = cache.cachedList.filter(x => x[cache.listId] != urlId);
                    // Update saved data:
                    this.saveCachedList(cache); 
                } else {
                    console.log("error, existing delete not found");
                }
                break;
        }
    }

    private setCachedList(cache: ICachedObject): Promise<boolean> {
        this.debug ? console.log("setCachedList(): ", cache) : null;
        return new Promise((resolve, reject) => {
            cache.getFn().subscribe(
                resp => {
                    this.debug ? console.log("getFn resp: ", resp) : null;
                    cache.cachedList = resp.body.results ? resp.body.results : resp.body;
                    resolve(this.saveCachedList(cache));
                },
                error => {
                    cache.cachedList = null;
                    console.log("error: ", error);
                    resolve(false);
                }
            )
        });
    }

    private saveCachedList(cache: ICachedObject): Promise<boolean> {
        this.debug ? console.log("saveCachedList: ", cache) : null;
        return new Promise((resolve, reject) => {
            if (cache.maxAge) {
                var dateObj = Date.now();
                dateObj += cache.maxAge;
                var expiry = new Date(dateObj);
                cache.expiry = expiry;
            } else {
                // Default to 8 hours:
                var dateObj = Date.now();
                dateObj += 28800000;
                var expiry = new Date(dateObj);
                cache.expiry = expiry;
            }
            set(cache.cacheKey, JSON.stringify(cache))
                .then(val => {
                    this.debug ? console.log("set complete") : null;
                    resolve(true);
                })
                .catch(err => {
                    console.log("error: ", err);
                    resolve(false);
                });
        });
    }
}