<up-dialog width="500">
    <up-dialog-header>Add GPS Device</up-dialog-header>
    <up-dialog-body>
        <up-loader spinner *ngIf="!formReady"></up-loader>
        <span *ngIf="formReady">
            <form #form="ngForm" novalidate id="ngForm" autocomplete="off" (ngSubmit)="save()" [formGroup]="detailForm">
                <button type="submit" style="display:none">Submit</button>
                <up-select formControlName="gPSProviderId" [formData]="detailForm.get('gPSProviderId')" [dataSource]="sharedService.gpsProviders" valueField="providerId" textField="description" label="Provider"></up-select>
                <up-loader spinner *ngIf="loadingDevices"></up-loader>
                <up-select *ngIf="devices?.length > 0 && !loadingDevices" formControlName="gPSDeviceId" [formData]="detailForm.get('gPSDeviceId')" [dataSource]="devices" valueField="id" [textField]="['name', ' - ', 'serialNumber']" label="Device" filterable>
<!--                     <up-button icon="plus" tooltip absolute message="Add New Geotab Device" (click)="addNewGeotabDevice()"></up-button>
 -->                </up-select>
                <p *ngIf="devices?.length == 0 && !loadingDevices">No devices found for provider</p>
                <p *ngIf="!checkingAssigned && alreadyAssignedVehicle">This device is already assigned to vehicle {{ alreadyAssignedVehicle.unitNumber }}. Saving will remove that device association.</p>
                <p *ngIf="!checkingAssigned && alreadyAssignedEquipment">This device is already assigned to equipment {{ alreadyAssignedEquipment.unitNumber }}. Saving will remove that device association.</p>
                <up-select formControlName="gPSDeviceTypeId" [formData]="detailForm.get('gPSDeviceTypeId')" [dataSource]="sharedService.gpsDeviceTypes" valueField="deviceTypeId" textField="description" label="Device Type"></up-select>
            </form>
        </span>

    </up-dialog-body>
    <up-dialog-footer>
        <span>
            <up-button (click)="onClose()">Cancel</up-button>
            <up-button (click)="save()" [disabled]="!formReady || !detailForm.valid || !checkingAssigned" [spinLoad]="checkingAssigned" filled>Save{{ !checkingAssigned && (alreadyAssignedVehicle || alreadyAssignedEquipment) ? ' and Remove' : '' }}</up-button>
        </span>
    </up-dialog-footer>
</up-dialog>