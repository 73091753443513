<up-dialog>
    <up-dialog-header>Geofence Zone Details</up-dialog-header>
    <up-dialog-body>
        <span *ngIf="formReady">
            <form #form="ngForm" novalidate id="ngForm" autocomplete="off" (ngSubmit)="save()"
                [formGroup]="geofenceDetailForm">
                <button type="submit" style="display:none">Submit</button>
                <up-input label="Zone Name" formControlName="zoneName" [formData]="geofenceDetailForm.get('zoneName')">
                </up-input>
                <up-select label="Zone Type" formControlName="zoneTypeId"
                    [formData]="geofenceDetailForm.get('zoneTypeId')" [dataSource]="zoneTypes" valueField="id"
                    textField="name">
                    <up-button icon="plus"></up-button>
                </up-select>
            </form>
        </span>
    </up-dialog-body>
    <up-dialog-footer>
        <span *ngIf="formReady">
            <up-button (click)="onDelete(data)">Clear Geofence</up-button>
            <up-button (click)="onClose(data)">Cancel</up-button>
            <up-button (click)="save()" [disabled]="!geofenceDetailForm.valid" filled>Save</up-button>
        </span>
    </up-dialog-footer>
</up-dialog>